import React, { useCallback, useEffect, useState } from "react";
import { m_size, aos_option } from "../../../common/constants";
import AOS from "aos";
import "aos/dist/aos.css";
import makeStyles from "@mui/styles/makeStyles";
import { inject, observer } from "mobx-react";
import { throttle } from "lodash";
import "./Chapter1.css";

const useStyles = makeStyles((theme) => ({
  bodyWrap: {
    width: "100%",
    minWidth: m_size.minWidth,
    maxWidth: m_size.maxWidth,
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 92%",
    backgroundSize: "100% 103.69px",
  },
  body: {
    position: "relative",
    width: "100%",
    minWidth: m_size.minWidth,
    maxWidth: m_size.maxWidth,
    margin: "auto",
  },
}));

const Chapter1 = ({ layout }) => {
  const classes = useStyles(layout);

  const [flag1, setFlag1] = useState(true);
  const [flag2, setFlag2] = useState(true);
  const [flag3, setFlag3] = useState(true);
  const [isMainBanner, setisMainBanner] = useState(true);

  const mainBannerClick = (event) => {
    window.location.href = "https://www.rozeus.com/home";
  };

  const mainBannerClose = (e) => {
    setisMainBanner(false);
  };

  const googleapp = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.bflysoft.rozeus";
  };

  const appleapp = () => {
    window.location.href =
      "https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887";
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-back",
    });
  });

  const listener = useCallback(() => {
    const scrollTop = window.scrollY || window.pageYOffset;

    const height = window.innerHeight;
    const section = document.querySelector("#top");
    const sectionHeight = section && section.getBoundingClientRect().height;

    if (height + scrollTop > sectionHeight + 800 && flag1) {
      setFlag1(false);
    }
    if (height + scrollTop > sectionHeight + 800 + 120 && flag2) {
      setFlag2(false);
    }
    if (height + scrollTop > sectionHeight + 800 + 240 && flag3) {
      setFlag3(false);
    }
  }, [flag1, flag2, flag3]);

  useEffect(() => {
    const throttleListener = throttle(listener, 300);
    window.addEventListener("scroll", throttleListener, true);
    return () => {
      window.removeEventListener("scroll", throttleListener, true);
    };
  }, [listener]);

  useEffect(() => {
    const scrollTop = window.scrollY || window.pageYOffset;
    const height = window.innerHeight;
    if (height + scrollTop > 600 + 120 && flag1) {
      setFlag1(false);
    }
    if (height + scrollTop > 600 + 265 + 120 + 160 && flag2) {
      setFlag2(false);
    }
    if (height + scrollTop > 600 + 265 + 120 + 320 && flag3) {
      setFlag3(false);
    }
  }, [flag1, flag2, flag3]);

  return (
    <div className={classes.bodyWrap}>
      <div className={classes.body}>
        {/* {isMainBanner &&
                    <div className='small_modal_container'>
                        <div className='small_Chapter1_main_banner'>
                            <img 
                            srcSet='/images/small/small_main_bannerx1.png 214w, /images/small/small_main_bannerx2.png 414w, /images/small/small_main_bannerx3.png 614w' 
                            alt='img_mobile' 
                            className='small_Chapter1_main_banner_img' onClick={mainBannerClick}/>
                            <img src='/images/small/btn_close.png' alt='btn_close' className='small_Chapter1_btn_closer' onClick={mainBannerClose}/>
                        </div>
                    </div>
                } */}
        <img
          srcSet="/images/small/body/small_Chapter_newsx1.png 214w, /images/small/body/small_Chapter_newsx2.png 414w, /images/small/body/small_Chapter_newsx3.png 614w"
          alt="img_mobile"
          className="small_Chapter1_News_img"
        />
        <React.Fragment>
          <div className="small_Chapter1_title_text">
            세상 모든 컨텐츠,
            <br />
            여기 다 모였다!
            <br />
            하루가 소중한
            <br />
            당신에게!
            <br />
            필요한 뉴스정보만!
          </div>
          <div style={{ height: "46px" }}></div>
          {!window.ReactNativeWebView && (
            <div className="small_Chapter1_button">
              <img
                src="/images/small/body/googleplay_download_normal.png"
                alt="btn_google"
                className="small_Chapter1_btn_google_m"
                onClick={googleapp}
              />
              <div style={{ width: "16px" }}></div>
              <img
                src="/images/small/body/appstore_download_normal.png"
                alt="btn_google"
                className="small_Chapter1_btn_google_m"
                onClick={appleapp}
              />
            </div>
          )}

          <div>
            <img
              data-aos="fade-up"
              data-aos-duration={aos_option.duration}
              data-aos-offset={aos_option.offset}
              srcSet="/images/small/body/small_Chapter1_mainx1.png 214w, /images/small/body/small_Chapter1_mainx2.png 414w, /images/small/body/small_Chapter1_mainx3.png 614w"
              alt="btn_google"
              className="small_Chapter1_bottom_img"
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default inject(({ layout }) => ({
  layout: layout,
}))(observer(Chapter1));

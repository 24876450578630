import React, { useState , useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import makeStyles from '@mui/styles/makeStyles';
import Pagination from '@mui/material/Pagination';
import Axios from 'axios';
import NoticeDetailmodal from './NoticeDetailmodal';
import './Noticemodal.css';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '600px',
        height: '460px',
    },
    body: {
        width: '100%',
        height: '100%',
        justifyContent:'space-between',
        alignItems:'center',
        margin: 'auto',
        bgcolor: 'background.paper',
        backgroundColor : 'white',
        border: '1px solid #ECEEF2',
        
    },
}));

const Noticemodal = ({ layout }) => {

    const classes = useStyles();
    const [title, setTitle] = useState(null);
    const [row, setRow] = useState(0);
    const [ isNoticeModalOn , setisNoticeModalOn]  = useState(false);
    const [ notice_id , setNoticeId]  = useState(0);
    const [ num , setNumId]  = useState(0);
    const [ totalRows, setTotalRows] = useState(0);
    const noticemodaldetail = (e) =>{
        setNoticeId(e.target.childNodes[0].textContent);
        setNumId(e.target.childNodes[1].textContent);
        setisNoticeModalOn(!isNoticeModalOn);
    }

    const NoticehandleClose = (e) => {
        setisNoticeModalOn(!isNoticeModalOn);
    }
    const paginationChange = (e) =>  {
        setTimeout(() => {
            const selectpage = document.querySelector(".Mui-selected").textContent*1-1;
            const path = 'https://api.rozeus.com/admin/api/v1/cs/notice/list?page='+selectpage+'&size=5'
            const config = {
                headers: {
                    'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                },
            }
            Axios.get(path, null, config)
            .then((response) => {
                if(response.data.results){
                    const targetData = response.data.results;     
                    targetData.map((item, index) => {
                        targetData[index].num = (index+1)+((response.data.pages.page/response.data.pages.size)*5);
                    })

                    setTotalRows(response.data.pages.total_rows);
                    setTitle(targetData);
                }
            }).catch(err => {
                return null;
            });
        }, 0);
    }


    useEffect(() => {  
        const path = 'https://api.rozeus.com/admin/api/v1/cs/notice/list?page=0&size=5'
        const config = {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
            },
        }
        Axios.get(path, null, config)
            .then((response) => {
                if(response.data.results){
                    const targetData = response.data.results;
                    setRow(response.data.pages.total_pages);
                    targetData.map((item, index) => {
                        setTitle(null);
                        targetData[index].num = index+1;
                    })
                    setTotalRows(response.data.pages.total_rows);
                    setTitle(targetData);
                }
            }).catch(err => {
                return null;
            });
    }, []);

    
    if (!title) return null;

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}> 
                <div className='noticemodal_title'>
                    공지사항&버전정보
                </div>
                <div style={{ height: '20px' }} />
                <div className='noticeymodal_text_center'>
                        {title.map(title => (
                                <div key={title.notice_id} className='noticeymodal_List' onClick={(e) => noticemodaldetail(e)}>
                                    <div className='noticeymodal_black'>
                                        {title.reg_dt}
                                    </div>
                                    <img src={'/images/modal/icon_right.png'} alt='noticeymodal_detail' className='noticeymodal_detail' style={{ width: '24px', height: '24px'}}/>
                                    <div className='noticeymodal_ellipsis'>
                                        <p dangerouslySetInnerHTML={{__html: title.title}}></p>
                                    </div>
                                    <div className='noticeymodal_hidden'>
                                        {title.notice_id}{title.num}
                                    </div>
                                </div> 
                        ))}
                </div>
                <div style={{ height: '17px' }} />
                <div className='noticeymodal_body_contents' >
                    <Pagination count={row} showFirstButton showLastButton onClick={(e) => paginationChange(e)}/>
                </div>
                {isNoticeModalOn && 
                <div className='NoticeDetail_modal_container'>
                    <div className='NoticeDetail_modal'>
                    <NoticeDetailmodal notice_id={notice_id} num={num} row={totalRows}> 
                    </NoticeDetailmodal>
                    <img src='/images/modal/icon_left.png' alt='coolicon' className='NoticeDetail_modal_button' onClick={NoticehandleClose}/>
                    </div>    
                </div>
                }  
            </div>
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Noticemodal));
import React from 'react';
import Service from '../components/common/footer/Service';
import SNSIcon from '../components/common/footer/SNSIcon';

import MService from '../components/small/common/footer/Service';
import MSNSIcon from '../components/small/common/footer/SNSIcon';

import FooterTemplate from '../templates/FooterTemplate';
import { Hidden } from '@mui/material';
import SmallFooterTemplate from '../templates/SmallFooterTemplate';

const FooterContainer = ({type}) => {

    return (
        <React.Fragment>
            <Hidden smDown>
                <FooterTemplate
                    service={<Service />}
                    snsIcon={<SNSIcon />}
                    type={type}
                />
            </Hidden>
            <Hidden smUp>
                <SmallFooterTemplate
                    Service={<MService />}
                    snsIcon={<MSNSIcon />}
                    type={type}
                />
            </Hidden>
        </React.Fragment>
    );

};

export default FooterContainer;

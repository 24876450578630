import React, { useLayoutEffect } from 'react';

import { SMALL, LARGE} from '../common/constants'

import MainTemplate from '../templates/MainTemplate';
import FooterContainer from './FooterContainer';
import Header from '../components/common/Header';
import MHeader from '../components/small/common/Header';
import { Hidden } from '@mui/material';
import { inject, observer } from 'mobx-react';

const MainContainer = ({ layout, menu }) => {

    useLayoutEffect(() => {
        // console.log("///",window.innerWidth);
        function updateSize() {
            const temp = layout.sizeMode;
            if(window.innerWidth < 600){
                temp !== SMALL && menu.deselect()
                layout.setSizeMode(SMALL);
            }else{
                temp !== LARGE && menu.deselect()
                layout.setSizeMode(LARGE);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    });

    return (
        <React.Fragment>
            <Hidden smDown>
                <MainTemplate
                    header={<Header />}
                    footer ={<FooterContainer type='main'/>}
                    headerSize={0}
                />
            </Hidden>
            <Hidden smUp>
                <MainTemplate
                    header={<MHeader/>}
                    footer={<FooterContainer type='main' />}
                    headerSize={0}
                />
            </Hidden>
        </React.Fragment>
    );
};

export default inject(({ layout, menu }) => ({
    layout: layout,
    menu: menu,
}))(observer(MainContainer));

import React from 'react';
import { inject, observer } from 'mobx-react';
import { m_size } from '../../../../common/constants'

import { Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RemoveIcon from '@mui/icons-material/Remove';
import './Service.css'

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
    },
    formControl: {
        margin: theme.spacing(0.5),
        width: '95%',
        color: 'white',
        border: '1px solid #FFFFFF',
        minWidth: '175px'
    }
}));

const Download = ({ layout }) => {

    const classes = useStyles();

    const handleChangeLink = (value) =>{
        switch(value.target.value){
            case 'Business' :
                window.open('http://www.rozeus.io/');
                break;
            case 'Service':
                window.open('http://www.rozeus.com/');
                break;
            case 'Bflysoft':
                window.open('http://www.bflysoft.co.kr/');
                break;
            case 'Eyesurfer':
                window.open('https://www.eyesurfer.com/');
                break;
            case 'wigo':
                window.open('https://wigo.ai/');
                break;
            case 'wigomon':
                window.open('http://www.wigomon.ai/#video');
                break;
            default :
                break;
        }
    }


    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <img src='/images/small/footer/Service_background_img.png' alt='small_Service_background_img' className='small_Service_background_img'/>
                    <div className='small_Service_family_site'>
                        <div className='small_Service_div'>
                        
                        </div>
                        <div style={{ width : '5%' }} />
                        <div className='small_Service_div'>
                            <FormControl variant='outlined' size='small'>
                                <InputLabel id="Family"  style={{ color: 'white' , marginTop : '5px'}}>Family site</InputLabel>
                                <Select 
                                    labelId='Family' 
                                    className={classes.formControl} 
                                    IconComponent={() => <RemoveIcon style={{ marginRight: '10px', color: 'white' }} />}
                                    onChange={(e) => handleChangeLink(e)}
                                    defaultValue={""}
                                >
                                    <MenuItem value="Service">
                                        <Typography variant="body2" >Rozeus Service</Typography>
                                    </MenuItem>
                                    <MenuItem value="Business">
                                        <Typography variant="body2" >Rozeus Business</Typography>
                                    </MenuItem>
                                    <MenuItem value="Bflysoft">
                                        <Typography variant="body2" >Bflysoft</Typography>
                                    </MenuItem>
                                    <MenuItem value="Eyesurfer">
                                        <Typography variant="body2" >Eyesurfer</Typography>
                                    </MenuItem>
                                    <MenuItem value="wigo">
                                        <Typography variant="body2" >WIGO</Typography>
                                    </MenuItem>
                                    <MenuItem value="wigomon">
                                        <Typography variant="body2" >WIGO_MON</Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
            </div>
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Download));
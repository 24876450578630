import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import makeStyles from '@mui/styles/makeStyles';

import { inject, observer } from 'mobx-react';
import './Chapter2.css'

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 430px',
        backgroundSize: '65% auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
    },
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <React.Fragment>
                    <div className= 'small_Chapter2_title_header'>
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            className= 'small_Chapter2_title_header_first_text'
                        >
                                                놓치면 안되는<br />
                                                모든 뉴스정보를 보여드려요
                        </div>
                    </div>
                    <div style={{height:'35px'}}></div>
                    <div className='small_Chapter2_title_body'>
                        <div className='small_Chapter2_title_body_first_img'>
                            <img 
                                data-aos='fade-up' 
                                data-aos-duration={aos_option.duration} 
                                data-aos-offset={aos_option.offset}     
                                srcSet='/images/small/body/small_Chapter2_topx1.png 214w, /images/small/body/small_Chapter2_topx2.png 414w, /images/small/body/small_Chapter2_topx3.png 614w' 
                                alt='btn_google' 
                                className='small_Chapter2_title_body_first_img'
                            />
                        </div>
                        <div style={{height:'16px'}}></div>
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            className='small_Chapter2_title_body_first_text'
                        >
                                                네이버에 없는 뉴스,<br />
                                                여기 다 있다!                  
                        </div>
                        <div style={{height:'16px'}}></div>
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            className='small_Chapter2_title_body_second_text'
                        >
                                                국내뉴스 99% 제공!                  
                        </div>
                        <div
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                        >
                            <div className='small_Chapter2_title_body_second_text'>
                                                    <div className='small_Chapter2_title_body_second_main_first_text'>
                                                        다른 곳에서는 볼 수 없는 
                                                    </div>
                                                    
                                                    <div className='small_Chapter2_title_body_second_sub_text'>
                                                        &nbsp;로얄티 언론사
                                                    </div>
                                                    <div className='small_Chapter2_title_body_second_main_second_text'>
                                                        부터
                                                    </div>               
                            </div>
                            <div className='small_Chapter2_title_body_fifth_text'>
                                                    실시간 속보, 정치, 경제, 연예, 일상 뉴스를 한번에                      
                            </div> 
                        </div>
                        <div style={{height:'50px'}}></div>        
                        <div className='small_Chapter2_title_body_second_img'>
                            <img 
                                data-aos='fade-up' 
                                data-aos-duration={aos_option.duration} 
                                data-aos-offset={aos_option.offset}     
                                srcSet='/images/small/body/small_Chapter2_centerx1.png 214w, /images/small/body/small_Chapter2_centerx2.png 414w, /images/small/body/small_Chapter2_centerx3.png 614w' 
                                alt='btn_google' 
                                className='small_Chapter2_title_body_second_img'
                            />
                        </div>          
                        <div style={{height:'32px'}}></div>  
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            className='small_Chapter2_title_body_first_text'
                        >
                                                최신 트렌드에<br />
                                                뒤쳐지지 않도록<br />
                                                요약은 저희가,<br />
                                                읽기만 하세요                 
                        </div>
                        <div style={{height:'16px'}}></div> 
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            className='small_Chapter2_title_body_second_text'
                        >
                                                실시간 뉴스부터,<br />
                                                이 시각 놓치면 아쉬운 뉴스를 보여 드려요.<br />
                                                그날의 헤드라인부터 최신뉴스까지<br />
                                                모두 놓치지 마세요<br />
                                                최신 트렌드는 로제우스에서!             
                        </div>
                        <div style={{height:'50px'}}></div>        
                        <div className='small_Chapter2_title_body_third_img'>
                            <img 
                                data-aos='fade-up' 
                                data-aos-duration={aos_option.duration} 
                                data-aos-offset={aos_option.offset}     
                                srcSet='/images/small/body/small_Chapter2_bottomx1.png 214w, /images/small/body/small_Chapter2_bottomx2.png 414w, /images/small/body/small_Chapter2_bottomx3.png 614w' 
                                alt='btn_google' 
                                className='small_Chapter2_title_body_third_img'
                            />
                        </div> 
                        <div style={{height:'32px'}}></div>  
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            className='small_Chapter2_title_body_first_text'
                        >
                                                나에게 꼭 맞춰진<br />
                                                맞춤 카드<br />
                                                무작위 추천은 이제 그만!    
                        </div>
                        <div style={{height:'16px'}}></div> 
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            className='small_Chapter2_title_body_second_text'
                        >
                                                보고 싶은 뉴스, 놓치고 싶지 않은 뉴스를<br />
                                                로제우스가 챙겨드릴게요.<br />
                                                간편하게 나만의 맞춤 카드 등록으로<br />
                                                보고싶은 뉴스만 쏙!          
                        </div>
                        <div style={{height:'10px'}}></div> 
                        <div
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset}                         
                        >
                            <div className= 'small_Chapter2_title_body_third_text'>
                                <div className='small_Chapter2_title_body_third_main_first_text'>
                                    #탈모
                                </div>    
                                <div className='small_Chapter2_title_body_third_sub_text'>
                                                    and
                                </div>                             
                                <div className='small_Chapter2_title_body_third_main_second_text'>
                                    영양제 #도쿄올림픽 #백신
                                </div>                                  
                                                    
                                <div className='small_Chapter2_title_body_third_sub_text'>
                                                    or
                                </div>
                                <div className='small_Chapter2_title_body_third_main_third_text'>
                                    백신예약
                                </div>                                           
                            </div>
                            <div className= 'small_Chapter2_title_body_third_text'>
                                                    #정치 #경제 #문화 #연예/오락 #기타
                            </div>
                        </div>

                        <div style={{height:'80px'}}></div>
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import makeStyles from '@mui/styles/makeStyles';

import { inject, observer } from 'mobx-react';
import './Chapter4.css'

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '80% auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
    },
}));

const Chapter4 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className='small_Chapter4_title_body_background_img'>
                <img 
                    srcSet='/images/small/body/small_Chapter4_bgx1.png 214w, /images/small/body/small_Chapter4_bgx2.png 414w, /images/small/body/small_Chapter4_bgx3.png 614w' 
                    alt='small_Chapter4_title_body_background_img' 
                    className='small_Chapter4_title_body_background_img'/>
            </div>
            <div className={classes.body}>
                <div style={{height:'40px'}}></div>
                <div 
                    data-aos='fade-up' 
                    data-aos-duration={aos_option.duration} 
                    data-aos-offset={aos_option.offset}   
                    className= 'small_Chapter4_title_header_first_text'
                >
                                                안녕하세요<br />
                                                TEAM ROZEUS<br />
                                                입니다
                </div>
                <div style={{height:'40px'}}></div>
                <div 
                    data-aos='fade-up' 
                    data-aos-duration={aos_option.duration} 
                    data-aos-offset={aos_option.offset}
                    className= 'small_Chapter4_title_header_second_text'
                >
                                                팀로제우스는<br />
                                                "새로운, 남들과는 다르게"<br />
                                                라는 키워드를 좋아해요.<br />
                                                같은 길을 걸어가기 보다는 새로운 길을<br />
                                                개척하거나 만들어 보자는 생각으로<br />
                                                일하고 있어요.<br />
                </div>
                <div style={{height:'20px'}}></div>
                <div 
                    data-aos='fade-up' 
                    data-aos-duration={aos_option.duration} 
                    data-aos-offset={aos_option.offset}
                    className= 'small_Chapter4_title_header_third_text'
                >
                                                미래를 기다려서는 안되며<br />
                                                우리 스스로 만들어야 하는 것이다.
                </div>
                <div style={{height:'5px'}}></div>
                <div 
                    data-aos='fade-up' 
                    data-aos-duration={aos_option.duration} 
                    data-aos-offset={aos_option.offset}                    
                    className= 'small_Chapter4_title_header_fourth_text'
                >
                                                - Simone Weil(시몬 베유) -
                </div> 
                <div style={{height:'20px'}}></div>        
                <div 
                    data-aos='fade-up' 
                    data-aos-duration={aos_option.duration} 
                    data-aos-offset={aos_option.offset}
                    className= 'small_Chapter4_title_header_second_text'
                >
                                                로제우스 사무실 벽면에 있는 글귀인데요,<br />
                                                이처럼 팀 로제우스는 새로운 길을 남들과 다르게<br />
                                                개척해 나가도록 노력하며<br />
                                                <div style={{height:'20px'}}></div>     
                                                믿을 수 있고 신뢰할 수 있는<br />
                                                건강한 미디어 생태계를 만들어 가기위해<br />
                                                회사와 함께 성장하고 있어요.
                </div>   
                <div style={{height:'50px'}}></div>    
                <div 
                    data-aos='fade-up' 
                    data-aos-duration={aos_option.duration} 
                    data-aos-offset={aos_option.offset}
                    className= 'small_Chapter4_title_header_Fifth_text'
                >
                                                # 수평적인 소통을 지향해요<br />
                                                # 엄격한 규칙은 NO,NO<br />
                                                # 자유로움 속에서 책임감있게 행동해요<br />
                                                # 서로 의지하며, 즐겁게 성장하고 있어요
                </div>
                <div style={{height:'60px'}}></div>  
                <div className='small_Chapter4_logo_img_div'>
                    <img 
                        data-aos='fade-up' 
                        data-aos-duration={aos_option.duration} 
                        data-aos-offset={aos_option.offset} 
                        src='/images/small/body/Chapter4_logo_img.png' 
                        alt='small_Chapter4_logo_img' 
                        className='small_Chapter4_logo_img'
                    />
                </div>  
                <div style={{height:'80px'}}></div>  
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter4));
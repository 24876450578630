import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

import { size } from '../../../common/constants'

import { Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RemoveIcon from '@mui/icons-material/Remove';
import ServiceModal from './modal/Servicemodal';
import PrivacyModal from './modal/Privacymodal';
import Noticemodal from './modal/Noticemodal';

import './Service.css';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '80px'
    },
    body: {
        width: '100%',
        maxWidth: size.fullmaxWidth,
        minWidth: size.fullminWidth,
        height: '80px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        margin: 'auto',
    },
    contents1 : {
        fontFamily: 'S-core_dream6_bold',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '26px',
        color:theme.palette.text.footer,
        paddingBottom:'6px'
    },
    formControl: {
        margin: theme.spacing(0.5),
        width: 166,
        color: 'white',
        border: '1px solid #FFFFFF',
    }
}));

const Download = ({ layout }) => {

    const classes = useStyles();
    const [isServoceModalOn, setisServiceModalOn] = useState(false);
    const [isPrivacyModalOn, setisPrivacyModalOn] = useState(false);
    const [isNoticeModalOn, setisNoticeModalOn] = useState(false);


    const ServicehandleClickOpen = (e) =>  {
        setisServiceModalOn(!isServoceModalOn);
    }

    const ServicehandleClose = (e) => {
        setisServiceModalOn(!isServoceModalOn);
    }

    const PrivacyhandleClickOpen = (e) =>{
        setisPrivacyModalOn(!isPrivacyModalOn);
    }


    const PrivacyhandleClose = (e) => {
        setisPrivacyModalOn(!isPrivacyModalOn);
    }

    const NoticehandleClickOpen = (e) => {
        setisNoticeModalOn(!isNoticeModalOn);
        // window.location.href = "https://dev2.service.rozeus.com/setting/notice"
    }
    const NoticehandleClickClose = (e) => {
        setisNoticeModalOn(!isNoticeModalOn);
    }


    const handleChangeLink = (value) =>{
        switch(value.target.value){
            case 'Business' :
                window.open('http://www.rozeus.io/');
                break;
            case 'Service':
                window.open('http://www.rozeus.com/');
                break;
            case 'Bflysoft':
                window.open('http://www.bflysoft.co.kr/');
                break;
            case 'Eyesurfer':
                window.open('https://www.eyesurfer.com/');
                break;
            case 'wigo':
                window.open('https://wigo.ai/');
                break;
            case 'wigomon':
                window.open('http://www.wigomon.ai/#video');
                break;
            default :
                break;
        }
    }

    return (
        <div className={classes.bodyWrap}>
            <div className= 'SNSIcon_title_left_center_img'>
                <img src='/images/footer/Rectangle 699.png' alt='img_mobile' className='Service_img' />
            </div>
            <div className={classes.body}>
                <div className= 'Service_title_left'>
                    <div style={{ display: 'flex', width: '100%', minWidth:'100%'}}>
                        <div style={{ flexGrow:1}}>
                            <div style={{display:'flex'}}>
                                <a href="mailto:pr@rozeus.com" className='Service_left_text'>서비스/제휴 문의</a>
                            </div>
                        </div>
                        <div style={{ width: '30px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <a href="mailto:cs@rozeus.com" className='Service_left_text'>고객센터</a>
                            </div>
                        </div>
                        <div style={{ width: '30px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <a  className='Service_left_text' onClick={ServicehandleClickOpen}>이용약관</a>
                            </div>
                        </div>
                        <div style={{ width: '30px' }} /> 
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <a  className='Service_left_text' onClick={PrivacyhandleClickOpen}>개인정보 처리방침</a>
                            </div>
                        </div>
                        <div style={{ width: '30px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <a  className='Service_left_text' onClick={NoticehandleClickOpen}>공지사항</a>
                            </div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <div className='Service_family_site'>
                                    <FormControl variant='outlined' size='small'>
                                        <InputLabel id="Family"  style={{ color: 'white', marginTop: '6px' }}>Family site</InputLabel>
                                        <Select 
                                            labelId='Family' 
                                            className={classes.formControl} 
                                            IconComponent={() => <RemoveIcon style={{ marginRight: '10px', color: 'white' }} />}
                                            onChange={(e) => handleChangeLink(e)}
                                            defaultValue={""}
                                        >
                                            <MenuItem value="Service">
                                                <Typography variant="body2" >Rozeus Service</Typography>
                                            </MenuItem>
                                            <MenuItem value="Business">
                                                <Typography variant="body2" >Rozeus Business</Typography>
                                            </MenuItem>

                                            <MenuItem value="Bflysoft">
                                                <Typography variant="body2" >Bflysoft</Typography>
                                            </MenuItem>
                                            <MenuItem value="Eyesurfer">
                                                <Typography variant="body2" >Eyesurfer</Typography>
                                            </MenuItem>
                                            <MenuItem value="wigo">
                                                <Typography variant="body2" >WIGO</Typography>
                                            </MenuItem>
                                            <MenuItem value="wigomon">
                                                <Typography variant="body2" >WIGO_MON</Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isServoceModalOn && 
                <div className='modal_container'>
                    <div className='Service_modal'>
                    <ServiceModal> 
                    </ServiceModal>
                    <img src='/images/footer/coolicon.png' alt='coolicon' className='Service_modal_button' onClick={ServicehandleClose}/>
                    </div>    
                </div>
                }
                {isPrivacyModalOn && 
                <div className='modal_container'>
                    <div className='Privac_modal'>
                    <PrivacyModal> 
                    </PrivacyModal>
                    <img src='/images/footer/coolicon.png' alt='coolicon' className='Privac_modal_button' onClick={PrivacyhandleClose}/>
                    </div>    
                </div>
                }  
                {isNoticeModalOn && 
                <div className='modal_container'>
                    <div className='Privac_modal'>
                    <Noticemodal> 
                    </Noticemodal>
                    <img src='/images/footer/coolicon.png' alt='coolicon' className='Notice_modal_button' onClick={NoticehandleClickClose}/>
                    </div>    
                </div>
                }              
            </div>
        </div>

    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Download));
import React from 'react';
import { size } from '../common/constants'

import makeStyles from '@mui/styles/makeStyles';
 
const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '80px',
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '100% 553px',
    },
    body: {
        width: '100%',
        height: '80px',
        minWidth: size.minWidth,
        // maxWidth: size.maxWidth,
        margin: 'auto',
    },
    color:{
        backgroundColor: theme.palette.footerDivider
    }
}));

const FooterTemplate = ({ snsIcon, service, link, type }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                {service}
                {type === 'main' && 
                    <React.Fragment>
                        {snsIcon}
                    </React.Fragment>
                }
            </div>
            {link}
        </div>
    )
}

export default FooterTemplate;
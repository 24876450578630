import React from 'react';
import { size , aos_option } from '../../common/constants'

import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import './Chapter3.css';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1488px',
        minWidth:size.minWidth,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 196px',
        backgroundSize: '714px 533px'
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '867px',
        margin:'auto'
        
    },
    contentWrap: {
        position: 'absolute', 
        left: '0px', 
        height: '100%',
        width: '100%',
    },
}));

const Chapter3 = ({ layout }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div>
                <img srcSet='/images/body/Chapter3_main_bgx1.png 1920w, /images/body/Chapter3_main_bgx2.png 2000w, /images/body/Chapter3_main_bgx3.png 3000w' alt='img_mobile' className='Chapter3_img_main' />
            </div>
            <div className={classes.body}>
                <div className={classes.contentWrap}>
                <div className="Chapter3_img_bg">
                    <img src='/images/body/Chapter3_sub_bg.png' alt='img_mobile' className='Chapter3_img_bg'/>
                </div>
                <div className='Chapter3_body_contents'>
                        <div className='Chapter3_body_contents_left'>
                            <React.Fragment>
                                <div className='Chapter3_body_contents_left_head'>
                                    <div>
                                        <img 
                                            data-aos='fade-up' 
                                            data-aos-duration={aos_option.duration} 
                                            data-aos-offset={aos_option.offset}
                                            srcSet='/images/body/Chapter3_img_top_leftx1.png 1920w, /images/body/Chapter3_img_top_leftx2.png 2000w, /images/body/Chapter3_img_top_leftx3.png 3000w' 
                                            alt='img_mobile' 
                                            className='Chapter3_img_top_left' 
                                        />
                                    </div>
                                </div>
                                <div 
                                    data-aos='fade-up' 
                                    data-aos-duration={aos_option.duration} 
                                    data-aos-offset={aos_option.offset}                                
                                    className='Chapter3_body_contents_left_cont'>
                                    <div className= 'Chapter3_title_left_first_text'>
                                                        다른 의견 브리핑
                                    </div>
                                    <div style={{ height: '22px' }} />
                                    <div className= 'Chapter3_title_left_second_text'>
                                                        하나의 이슈를 다양한 시선으로<br />
                                                        볼 수 있도록 도와드려요
                                    </div>
                                    <div style={{ height: '16px' }} />
                                    <div className= 'Chapter3_title_left_third_text'>
                                                        로제우스에서 준비했습니다.<br />
                                                        왜곡된 시선으로 바라보는 뉴스가 아닌 사용자에게 올바른<br />
                                                        인사이트를 제공하기 위한 "다른의견 뉴스보기"<br />
                                                        내가 선택한 기사를 다양한 관점에서 생각할 수 있도록<br />
                                                        비슷한 의견 기사 vs 다른의견 기사를 볼 수 있어요
                                    </div>
                                </div>
                            </React.Fragment>
                        </div> 
                        <div className='Chapter3_body_contents_right'>
                        <React.Fragment>
                            <div 
                                data-aos='fade-up' 
                                data-aos-duration={aos_option.duration} 
                                data-aos-offset={aos_option.offset}                              
                                className='Chapter3_body_contents_right_head'>
                                <div className= 'Chapter3_title_right_first_text'>
                                                        실시간 검색어 랭킹
                                </div>
                                <div style={{ height: '22px' }} />
                                <div className= 'Chapter3_title_right_second_text'>
                                                        더욱 강력해진 실시간 검색어 랭킹<br />
                                                        연관 키워드 분석으로<br />
                                                        폭 넓은 이슈탐색까지!
                                </div>
                                <div style={{ height: '16px' }} />
                                <div className= 'Chapter3_title_right_third_text'>
                                                        이제는 로제우스에서 실시간 검색 랭킹을 만나보세요<br />
                                                        궁금하지만 사라져버린 실시간 검색어 서비스,<br />
                                                        로제우스에 있습니다!<br />
                                                        실시간 검색어 랭킹의 키워드를 AI빅데이터 분석을 통해<br />
                                                        이슈 키워드, 긍정 키워드, 부정키워드 과련 뉴스까지 확인할 수 있어요
                                </div>
                                </div>
                                <div className='Chapter3_body_contents_right_cont'>
                                    <div>
                                        <img 
                                            data-aos='fade-up' 
                                            data-aos-duration={aos_option.duration} 
                                            data-aos-offset={aos_option.offset}
                                            srcSet='/images/body/Chapter3_img_bottom_rightx1.png 1920w, /images/body/Chapter3_img_bottom_rightx2.png 2000w, /images/body/Chapter3_img_bottom_rightx3.png 3000w' 
                                            alt='img_mobile' 
                                            className='Chapter3_img_top_right' 
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3));
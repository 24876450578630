import React from 'react';
import { inject, observer } from 'mobx-react';

import { size } from '../../../common/constants'

import makeStyles from '@mui/styles/makeStyles';
import ICB from '../ICB';
import './SNSIcon.css';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto'
    },
    body: {
        width: '100%',
        maxWidth: size.fullmaxWidth,
        minWidth: size.fullminWidth,
        display:'flex',
        alignItems:'center',
        margin: 'auto',
    },
    icon : {
        width:'32px',
        height:'32px',
        marginLeft:'12px',
        cursor:'pointer'
    }
}));

const SNSIcon = ({ layout }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className= 'SNSIcon_title_left_center_img'>
                <img src='/images/footer/Rectangle 702.png' alt='img_mobile' className='SNS_img' />
            </div>
            <div className={classes.body}>
                <div className= 'SNSIcon_icon_left'>
                <div style={{ height : "30px"}}/>
                    <div className= 'SNSIcon_icon_left_text'>
                        All rights reserved ⓒ Bflysoft Corp 2021 <br />

                    </div>
                    <div style={{ height : "14px"}}/>
                    <div className= 'SNSIcon_icon_left_text_center'>
                        <div style={{ flexGrow:0.2}} className='SNSIcon_icon_left_text_center_text'>
                            비플라이소프트㈜ 
                        </div>
                        <div style={{ flexGrow:30}} className='SNSIcon_icon_left_text_center_title'>                       
                            서울특별시 중구 퇴계로 385(흥인동 132) 준타워 9층
                        </div>
                    </div>
                    <div style={{ height : "4px"}}/>
                    <div className= 'SNSIcon_icon_left_text_center'>
                        <div style={{ flexGrow:0.2}} className='SNSIcon_icon_left_text_center_text'>
                            팀로제우스
                        </div>
                        <div style={{ flexGrow:30}} className='SNSIcon_icon_left_text_center_title'>                       
                            서울특별시 중구 퇴계로 385(흥인동 132) 준타워 8층
                        </div>
                    </div>
                    <div style={{ height : "30px"}}/>
                    <div style={{ display: 'flex', width: '100%', minWidth:'100%'}}>
                        <div style={{ flexGrow:1}}>
                            <div style={{display:'flex'}}>
                                <div className='SNSIcon_icon_left_text_bottom'>
                                    로제우스는 정확한 뉴스 정보를 수집하기 위해 노력하고 있으며, 정보 제공에 따른 법적 책임을 지지 않습니다.
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '275px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <ICB imgPath={'/images/footer/sns_blog 1'} alt='blog' width='30px' height='30px' handleClick={(e) => window.open('https://blog.naver.com/bflysoft_biz')}/>
                            </div>
                        </div>
                        <div style={{ width: '12px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <ICB imgPath={'/images/footer/ic_facebook_normal'} alt='facebook' width='30px' height='30px' handleClick={(e) => window.open('https://www.facebook.com/rozeuskr/')}/>
                            </div>
                        </div>
                        <div style={{ width: '12px' }} /> 
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <ICB imgPath={'/images/footer/ic_insta_normal'} alt='insta' width='30px' height='30px' handleClick={(e) => window.open('https://www.instagram.com/rozeus_official/')}/>
                            </div>
                        </div>
                        <div style={{ width: '12px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <ICB imgPath={'/images/footer/ic_twiter_normal'} alt='twiter' width='30px' height='30px' handleClick={(e) => window.open('https://twitter.com/rozeus2')}/>
                            </div>
                        </div>
                        <div style={{ width: '12px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <ICB imgPath={'/images/footer/googleplay_download_normal'} alt='googleplay' width='85px' height='30px' handleClick={(e) => window.open('https://play.google.com/store/apps/details?id=com.bflysoft.rozeus')}/>
                            </div>
                        </div>
                        <div style={{ width: '12px' }} />
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <ICB imgPath={'/images/footer/appstore_download_normal'} alt='appstore' width='85px' height='30px' handleClick={(e) => window.open('https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887')}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout
}))(observer(SNSIcon));
export const SMALL = 'small';
export const LARGE = 'large';
export const LIGHT = 'light';

export const themeType = {
    SMALL_LIGHT: SMALL + '_' + LIGHT,    
    LARGE_LIGHT: LARGE + '_' + LIGHT,
}

export const size = {
    maxWidth:1362,
    minWidth:1362,
    toolbarWidth: 1362,
    imageWidth: 1350,
    fullminWidth : 1362,
    fullmaxWidth : 1362,
}

export const m_size = {
    maxWidth: 600,
    minWidth: 300,
    fullminWidth : 300,
    fullmaxWidth : 320
}

export const aos_option = {
        duration : 1200,
        offset : 150
}
import React, { useCallback, useEffect, useState } from "react";
import { m_size } from "../../../common/constants";

import { AppBar, Collapse } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { inject, observer } from "mobx-react";
import { throttle } from "lodash";
import ServiceModal from "../common/footer/modal/Servicemodal";
import Noticemodal from "../common/footer/modal/Noticemodal";
import PrivacyModal from "../common/footer/modal/Privacymodal";
import "./Header.css";
import { isAndroid, isIOS } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    position: "relative",
    width: "calc(100% - 32px)",
    minWidth: m_size.minWidth,
    maxWidth: m_size.maxWidth,
    margin: "0px 16px",
    height: "50px",
    display: "flex",
    alignItems: "center",
  },
  toolbar2: {
    position: "relative",
    width: "calc(100% - 32px)",
    minWidth: m_size.minWidth,
    maxWidth: m_size.maxWidth,
    margin: "0px 16px",
    height: "50px",
    display: "flex",
    alignItems: "center",
  },
  toolbarContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  logoArea: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuArea: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "35px",
    height: "50px",
    display: "flex",
    zIndex: 10,
  },
  iconArea: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    zIndex: 11,
  },
  bottomtoolbar: {
    position: "relative",
    width: "100%",
    minWidth: m_size.minWidth,
    maxWidth: m_size.maxWidth,
    height: "50px",
    display: "flex",
    alignItems: "center",
    bottom: "0px",
    position: "fixed",
    backgroundColor: "white",
  },
  Header_banner: {
    maxWidth: m_size.fullmaxWidth,
    border: "1px #E2D5C5",
  },
  Header_banner_exit: {
    width: "22px",
    height: "22px",
    position: "relative",
    marginTop: "3px",
    left: "289px",
  },
}));

const Header = ({ layout, menu }) => {
  const classes = useStyles();

  const [eleVal, setEleVal] = useState(false);
  const [eleVal2, setEleVal2] = useState(false);
  const [isServoceModalOn, setisServiceModalOn] = useState(false);
  const [isNoticeModalOn, setisNoticeModalOn] = useState(false);
  const [isPrivacyModalOn, setisPrivacyModalOn] = useState(false);

  const handleBannerClick = (event) => {
    if (isAndroid === true) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.bflysoft.rozeus";
    }
    if (isIOS === true) {
      window.location.href =
        "https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887";
    }
  };

  if (eleVal) {
  }

  const HeaderServicehandleClickOpen = (e) => {
    setisServiceModalOn(!isServoceModalOn);
  };

  const HeaderServicehandleClose = (e) => {
    setisServiceModalOn(!isServoceModalOn);
  };

  const HeaderNoticehandleClickOpen = (e) => {
    setisNoticeModalOn(!isNoticeModalOn);
  };

  const HeaderNoticehandleClickClose = (e) => {
    setisNoticeModalOn(!isNoticeModalOn);
  };

  const HeaderPrivacyhandleClickOpen = (e) => {
    setisPrivacyModalOn(!isPrivacyModalOn);
  };

  const HeaderPrivacyhandleClose = (e) => {
    setisPrivacyModalOn(!isPrivacyModalOn);
  };

  const handleBannerExitClick = (event) => {
    layout.setBanner(false);
  };

  const listener = useCallback(() => {
    if (window.scrollY || window.pageYOffset > 0) {
      setEleVal(true);
    } else {
      setEleVal(false);
    }
    if (window.scrollY && window.pageYOffset > 1) {
      setEleVal2(true);
    } else {
      setEleVal2(false);
    }
  }, []);

  useEffect(() => {
    const throttleListener = throttle(listener, 300);
    window.addEventListener("scroll", throttleListener);
    return () => {
      window.removeEventListener("scroll", throttleListener);
    };
  }, [listener]);

  return (
    <React.Fragment>
      <div id="back-to-top-anchor" />
      {!window.ReactNativeWebView && (
        <Collapse in={layout.banner}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F8EFE4",
            }}
            className={classes.banner}
          >
            <img
              src="/images/common/ic_close_btn_normal.svg"
              alt="Floating"
              className={classes.Header_banner_exit}
              style={{ zIndex: 5 }}
              onClick={(e) => handleBannerExitClick()}
            />
            <img
              src="/images/small/top_banner_m.png"
              alt="Floating"
              className={classes.Header_banner}
              style={{ width: "420px", zIndex: 1 }}
              // height: "70px",
              onClick={(e) => handleBannerClick(false)}
            />
          </div>
        </Collapse>
      )}

      {!eleVal2 && (
        <div className={classes.toolbar}>
          <div className={classes.toolbarContent}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={"/images/common/logo.png"}
                  alt="logo_rozeus"
                  style={{ width: "86px", height: "15px" }}
                  onClick={(e) => (window.location.href = "/")}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {eleVal2 && (
        <AppBar position="sticky" elevation={0}>
          <div className={classes.toolbar2}>
            <div className={classes.toolbarContent}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={"/images/common/logo.png"}
                    alt="logo_rozeus"
                    style={{ width: "86px", height: "15px" }}
                    onClick={(e) => (window.location.href = "/")}
                  />
                </div>
              </div>
            </div>
          </div>
        </AppBar>
      )}
      <AppBar position="sticky" elevation={0}>
        <div className={classes.bottomtoolbar}>
          <div className={classes.toolbarContent}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", width: "30%" }}>
                  <a
                    className="Header_bottom_Service"
                    onClick={HeaderServicehandleClickOpen}
                  >
                    이용약관
                  </a>
                </div>
                <div style={{ display: "flex", width: "40%" }}>
                  <a
                    className="Header_bottom_Service"
                    onClick={HeaderPrivacyhandleClickOpen}
                  >
                    개인정보 처리 방침
                  </a>
                </div>
                <div style={{ display: "flex", width: "30%" }}>
                  <a
                    className="Header_bottom_Service"
                    onClick={HeaderNoticehandleClickOpen}
                  >
                    공지사항
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppBar>
      {isServoceModalOn && (
        <div className="small_modal_container">
          <div className="small_Service_modal">
            <ServiceModal></ServiceModal>
            <img
              src="/images/footer/coolicon.png"
              alt="coolicon"
              className="small_Service_modal_button"
              onClick={HeaderServicehandleClose}
            />
          </div>
        </div>
      )}
      {isPrivacyModalOn && (
        <div className="small_modal_container">
          <div className="small_Privac_modal">
            <PrivacyModal></PrivacyModal>
            <img
              src="/images/footer/coolicon.png"
              alt="coolicon"
              className="small_Privac_modal_button"
              onClick={HeaderPrivacyhandleClose}
            />
          </div>
        </div>
      )}
      {isNoticeModalOn && (
        <div className="small_modal_container">
          <div className="small_Notice_modal">
            <Noticemodal></Noticemodal>
            <img
              src="/images/footer/coolicon.png"
              alt="coolicon"
              className="small_Notice_modal_button"
              onClick={HeaderNoticehandleClickClose}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default inject(({ layout, menu }) => ({
  layout: layout,
  menu: menu,
}))(observer(Header));

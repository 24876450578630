import React, { useEffect , useState} from 'react';
import { size } from '../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { post } from 'axios';
import { Typography, Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import './Chapter6.css';
import CustomButton2 from '../common/CustomButton2';
import CustomButton from '../common/CustomButton'

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1140px',
        margin: 'auto'
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '1098px',
        margin:'auto',
        paddingLeft: '40px',
        paddingRight: '40px'
    },
    dialogBody:{
        width: '500px', 
        height: '272px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
    },
    input:{
        background: '#FFFFFF',
        border: '1px solid #E1E1E1',
        borderRadius: '2px',
        fontFamily: 'paybooc',
        fontSize: '16px',
        '&:hover': {
            border: '1px solid #E1E1E1',
        },
        '&:focus' :{
            outline:'none',
            border: '1px solid #E1E1E1',
        },
        '&::placeholder': {
            color:'#868686'
        }
    }
}));

const Chapter6 = ({ layout }) => {

    const classes = useStyles();
    const [msg, setMsg] = useState({});
    const [msgPop, setMsgPop] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [tel, setTel] = useState('');
    const [title, setTitle] = useState('');
    const [bChecked, setChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState(new Set());

    const checkHandler = ({ target }) => {
        setChecked(!bChecked);
        checkedItemHandler(Chapter6.id, target.checked);
    };

    const checkedItemHandler = (id, isChecked) => {
        if (isChecked) {
            checkedItems.add(id);
            setCheckedItems(checkedItems);
        } else if (!isChecked && checkedItems.has(id)) {
            checkedItems.delete(id);
            setCheckedItems(checkedItems);
        }
    };
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const handleChange = (e) => {
        switch(e.target.name){
            case 'name':
                setName(e.target.value);
                return;
            case 'email':
                setEmail(e.target.value);
                return;
            case 'message':
                setMessage(e.target.value);
                return;
            case 'title':
                setTitle(e.target.value);
                return;
            case 'tel':
                setTel(e.target.value);
                return;
            default:
                return;
        }
    }

    const handleClick = () => {

        var check = true;
        if(bChecked===false){
            check = false;
            setMsg({ title: '전송실패', text:'개인정보 수집 및 이용에 동의해주세요.'});
        }


        if (message.length < 2) {
            check = false;
            if (message.length === 0) {
                setMsg({ title:'전송실패',text:'Message을 입력해주세요'});
            } else {
                setMsg({ title: '전송실패', text:'Message는 두글자 이상 입력해주세요'});
            }
        }

        if (title.length < 2) {
            check = false;
            if (title.length === 0) {
                setMsg({ title: '전송실패', text:'제목을 입력해주세요.'});
            } else {
                setMsg({ title: '전송실패', text:'제목은 두글자 이상 입력해 주세요.'});
            }
        }

        if (email.length === 0) {
            check = false;
            setMsg({ title: '전송실패', text:'이메일을 입력해주세요'});
        } else {
            const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
            if (!reg_email.test(email)) {
                check = false;
                setMsg({ title: '전송실패', text:'올바르지 않은 메일 형식입니다.'})
            }
        }

        if (tel.length < 2) {
            check = false;
            if (tel.length === 0) {
                setMsg({ title: '전송실패', text:'전화번호를 입력해주세요'});
            } else {
                setMsg({ title: '전송실패', text:'올바르지 않은 전화번호 형식입니다'});
            }
        }


        if (name.length < 2) {
            check = false;
            if (name.length === 0) {
                setMsg({ title: '전송실패', text:'이름을 입력해주세요.'});
            } else {
                setMsg({ title: '전송실패', text:'이름은 두글자 이상 입력해주세요'});
            }
        }

        let question = "이름 ="+name+"\r\n전화번호="+tel+"\r\n이메일="+email+"\r\n문의내용="+message+"\r\n"

        if(check){
            const config = {
                headers: {
                        'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                        "Access-Control-Allow-Origin" : "*",
                        "Content-Type" : "application/json"
                        },
            }         
            post('https://api.rozeus.com/admin/api/v1/cs/qnas/service' , {"reg_id" :'0', "qna_tp" : '2' , "title" : title , "question" :question }, config)
                    .then((response) => {
                        setMsg({ title: '전송완료', text: '작성하신 내용이 전송완료되었습니다.' });
                        setMsgPop(true);
                    }).catch(err => {
                                    return null;
                    });
        }else{
            setMsgPop(true);
        }
    }

    const handleClose = () =>{
        setMsgPop(false);
    }

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <div className='Chapter6_body_contents'>
                    <div className='Chapter6_body_contents_left'>
                        <div style={{ height: '70px' }} />
                        <React.Fragment>
                            <div className='Chapter6_body_contents_left_head'>
                                <img  srcSet='/images/body/Chapter6_left_topx1.png 1920w, /images/body/Chapter6_left_topx2.png 2000w, /images/body/Chapter6_left_topx3.png 3000w' alt='img_mobile' className='Chapter6_img_cont_main' />
                            </div>
                            <div style={{ height : "40px"}}></div>
                            <div className='Chapter6_body_contents_left_cont'>
                                <div className= 'Chapter6_body_left_first_cont_icon'>
                                    <img srcSet='/images/body/Chapter6_left_bottom_emailx1.png 1920w, /images/body/Chapter6_left_bottom_emailx2.png 2000w, /images/body/Chapter6_left_bottom_emailx3.png 3000w' alt='img_mobile' className='Chapter6_img_cont_email_icon' />
                                    <div style={{ width : "13px"}}></div>
                                    <div className='Chapter6_img_cont_title'>
                                        E-mail :
                                    </div>
                                    <div className='Chapter6_img_cont_text'>
                                        &nbsp;pr@rozeus.com
                                    </div>
                                </div>
                                <div className= 'Chapter6_body_left_first_third_icon'>
                                    <img srcSet='/images/body/Chapter6_left_bottom_addressx1.png 1920w, /images/body/Chapter6_left_bottom_addressx2.png 2000w, /images/body/Chapter6_left_bottom_addressx3.png 3000w'  alt='img_mobile' className='Chapter6_img_cont_adress_icon' />
                                    <div style={{ width : "13px"}}></div>
                                    <div className='Chapter6_img_cont_title'>
                                        Adress :
                                    </div>
                                    <div className='Chapter6_img_cont_text'>
                                        &nbsp;서울특별시 중구 퇴계로 385(흥인동 132) 준타워 8층
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </div> 
                    <div className='Chapter6_body_contents_right'>
                        <div style={{ height: '70px' }} />
                        <div style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width: '100%', height: '504px' ,marginLeft: '5%'}}>
                            <div>
                                <div className= 'Chapter6_head_right_first_head'>
                                            서비스/제휴 문의  
                                </div>
                                <div style={{ height : "40px"}}></div>
                                <div style={{ display: 'flex', width: '100%', minWidth:'100%'}}>
                                    <div style={{ flexGrow:1}}>
                                        <div style={{display:'flex'}}>
                                            <Typography variant='body2' className='Chapter6_head_right_title'>이름</Typography>
                                        </div>
                                        <div style={{height:'12px'}} />
                                        <input type='text' name='name' className={classes.input} placeholder='이름을 입력해주세요.' style={{ height:'50px' ,  width: '100%'}} onChange={handleChange}/>
                                    </div>
                                    <div style={{ width: '12px' }} />
                                    <div style={{ flexGrow: 1 }}>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant='body2' className='Chapter6_head_right_title'>전화번호</Typography>
                                        </div>
                                        <div style={{height:'12px'}} />
                                            <input type='text' name='tel' className={classes.input} placeholder='전화번호를 입력해주세요.' style={{ height: '50px' , width: '98%' }} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div style={{height:'26px'}} />
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minWidth: '100%'}}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant='body2' className='Chapter6_head_right_title'>이메일 주소</Typography>
                                    </div>
                                    <div style={{height:'12px'}} />
                                    <input type='text' name='email' className={classes.input} placeholder='이메일 주소를 입력해 주세요'  style={{ height: '50px' }} onChange={handleChange}/>
                                </div>
                                <div style={{height:'26px'}} />
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minWidth: '100%'}}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant='body2' className='Chapter6_head_right_title'>제목</Typography>
                                    </div>
                                    <div style={{height:'12px'}} />
                                    <input  type='text' name='title' className={classes.input} placeholder='제목을 입력해 주세요' style={{ height: '50px' }} onChange={handleChange}/>
                                </div>
                                <div style={{height:'26px'}} />
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minWidth: '100%', minHeight:'275px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant='body2' className='Chapter6_head_right_title'>문의내용</Typography>
                                    </div>
                                    <div style={{height:'12px'}} />
                                    <textarea name='message'  className={classes.input} placeholder='문의내용을 입력해주세요'  style={{ resize: 'vertical', minHeight: '240px', height: '100%' }} onChange={handleChange}/>
                                </div>
                                <div style={{ height: '30px' }} />
                            </div>
                            <div className='Chapter6_body_service'>
                                <div style={{ flexGrow:1}}>
                                    <div className='Chapter6_body_service_title'>
                                        ● 
                                    </div>
                                </div>
                                <div style={{ flexGrow:10}}>
                                    <div className='Chapter6_body_service_title'>
                                        비즈니스 제휴를 목적으로 수집 및 이용 등 처리에 있어 아래 사항을 확인해주세요
                                        <div style={{height:'12px'}} />
                                    </div>
                                </div>
                            </div>
                            <div className = 'Chapter6_body_left_bottom'>
                                <div style={{height:'18px'}} />
                                <div className= 'Chapter6_body_left_title_text'>
                                            1. 개인정보 수집 및 이용 목적
                                </div>
                                <div style={{height:'4px'}} />
                                <div className= 'Chapter6_body_left_cont_text'>
                                            제품 소개, 파트너쉽 요청 상담 등 고객 문의 처리
                                </div> 
                                <div style={{height:'16px'}} />
                                <div className= 'Chapter6_body_left_title_text'>
                                            2. 수집하는 개인정보의 항목
                                </div>
                                <div style={{height:'4px'}} />
                                <div className= 'Chapter6_body_left_cont_text'>
                                            필수 항목: 회사이름, 이메일 주소, 전화번호
                                </div>     
                                <div style={{height:'16px'}} />
                                <div className= 'Chapter6_body_left_title_text'>
                                            3. 개인정보 보유 및 이용기간
                                </div>
                                <div style={{height:'4px'}} />
                                <div className= 'Chapter6_body_left_cont_text'>
                                            목적 달성 후 90일까지 보관 후 파기
                                </div>
                                <div style={{height:'20px'}} />
                            </div>
                            <div style={{ display: 'flex', width: '100%', minWidth:'100%' , marginTop: '20px'}}>
                                <div style={{ flexGrow:1}}>
                                    <div style={{display:'flex'}}>
                                        <input type='checkbox'  className='Chapter6_head_right_checkbox' checked={bChecked} onChange={(e) => checkHandler(e)}/>
                                    </div>
                                </div>                                 
                                <div style={{ flexGrow:1}}>
                                    <div style={{display:'flex'}}>
                                        <div className='Chapter6_head_right_bottom_text'>
                                            제휴를 목적으로 개인정보를 수집 및 이용함에 동의합니다.
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '12px' }} />
                                <div style={{width:'100%'}}>
                                    <CustomButton
                                        text='문의하기'
                                        size={{ width: '100%', height: '50px'}}
                                        onclick={handleClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={msgPop}>
                <div style={{ height: '40px' }} />
                    <div className={classes.dialogBody}>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-between', height:'100%'}}>
                            <Typography variant='subtitle2'>{msg.title}</Typography>
                            <div style={{ height: '70px' }} />
                                <Typography variant='body2' style={{ flexGrow:1, fontFamily: 's-core_dream4_regular' }} >{msg.text}</Typography>
                                <CustomButton2
                                    text='확인'
                                    size={{ width: '120px', height: '44px' }}
                                    onclick={handleClose} />
                            </div>
                    </div>
                <div style={{ height: '30px' }} />
            </Dialog>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter6));
import React, { useEffect } from "react";
import { m_size, aos_option } from "../../../common/constants";
import AOS from "aos";
import "aos/dist/aos.css";
import makeStyles from "@mui/styles/makeStyles";

import { inject, observer } from "mobx-react";
import "./Chapter6.css";

const useStyles = makeStyles((theme) => ({
  bodyWrap: {
    width: "100%",
    minWidth: m_size.minWidth,
    maxWidth: m_size.maxWidth,
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80% auto",
  },
  body: {
    width: "100%",
    minWidth: m_size.minWidth,
    maxWidth: m_size.maxWidth,
  },
}));

const Chapter6 = ({ layout }) => {
  const classes = useStyles(layout);

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-back",
    });
  });

  return (
    <div className={classes.bodyWrap}>
      <div className={classes.body}>
        <div style={{ height: "60px" }} />
        <div className="small_Chapter6_header_title">세상 모든 컨텐츠</div>
        <div style={{ height: "20px" }} />

        <img
          srcSet="/images/small/body/small_Chapter6_rozeusx1.png 214w, /images/small/body/small_Chapter6_rozeusx2.png 414w, /images/small/body/small_Chapter6_rozeusx3.png 614w"
          alt="small_Chapter6_rozeus_img"
          className="small_Chapter6_rozeus_img"
        />
        <div style={{ height: "40px" }} />
        <div className="small_Chapter6_boder_title">
          지금 바로 로제우스를
          <br />
          다운로드 하세요!
        </div>
        <div style={{ height: "50px" }} />
        <img
          data-aos="fade-up"
          data-aos-duration={aos_option.duration}
          data-aos-offset={aos_option.offset}
          srcSet="/images/small/body/small_Chapter6_bottomx1.png 214w, /images/small/body/small_Chapter6_bottomx2.png 414w, /images/small/body/small_Chapter6_bottomx3.png 614w"
          alt="small_Chapter6_bottom_img"
          className="small_Chapter6_bottom_img"
        />
      </div>
    </div>
  );
};

export default inject(({ layout }) => ({
  layout: layout,
}))(observer(Chapter6));

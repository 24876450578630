import React, { useCallback, useEffect, useState } from 'react';
import { size } from '../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import { throttle } from 'lodash';
import './Chapter1.css';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1000px',
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '100% 553px',
    },
    body: {
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '1754px',
        margin:'auto',
        fontFamily: 'paybooc'
    },
    contentWrap :{
        height: '980px'
    },
}));


const Chapter1 = ({ layout }) => {

    const classes = useStyles();
    const [flag1, setFlag1] = useState(true);
    const [flag2, setFlag2] = useState(true);
    const [flag3, setFlag3] = useState(true);

    const [ isMainBanner, setisMainBanner] = useState(true);

    const googleapp  = () => {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.bflysoft.rozeus'
    } 

    const appleapp = () => {
        window.location.href = 'https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887'
    }

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    const mainBannerClick = event => {

        window.location.href = "https://www.rozeus.com/home"
    
    };
    
    const mainBannerClose = (e) =>{
        setisMainBanner(false);
    }
    


    const listener = useCallback(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section && section.getBoundingClientRect().height;

        if ((height + scrollTop) > (sectionHeight + 820) && flag1) {
            setFlag1(false)
        }
        if ((height + scrollTop) > (sectionHeight + 820 + 160) && flag2) {
            setFlag2(false)
        }
        if ((height + scrollTop) > (sectionHeight + 820 + 320) && flag3) {
            setFlag3(false)
        }
    }, [flag1, flag2, flag3]);

    useEffect(() => {    
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener, true);
        return () => {
            window.removeEventListener("scroll", throttleListener, true);

        };
    }, [listener]);


    useEffect(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        if ((height + scrollTop) > (920 + 120) && flag1) {
            setFlag1(false)
        }
        if ((height + scrollTop) > (920 + 265 + 120 + 160) && flag2) {
            setFlag2(false)
        }
        if ((height + scrollTop) > (920 + 265 + 120 + 320) && flag3) {
            setFlag3(false)
        }
    }, [flag1, flag2, flag3]);

    return (
        <div className={classes.bodyWrap}>
            <div>
                <img srcSet='/images/body/gradation_bgx1.png 1920w, /images/body/gradation_bgx2.png 2000w, /images/body/gradation_bgx3.png 3000w' alt='img_mobile' className='Chapter1_img_main' />
            </div>
            <div className={classes.body}>
                <div className={classes.contentWrap}> 
                {isMainBanner &&
                    <div className='modal_container'>
                        <div className='Chapter1_main_banner'>
                            <img srcSet='/images/common/main_bannerx1.png 1920w, /images/common/main_bannerx2.png 2000w, /images/common/main_bannerx3.png 3000w' alt='main_banner' className='Chapter1_main_banner_img' onClick={mainBannerClick}/>
                            <img src='/images/common/btn_close.png' alt='btn_close' className='Chapter1_btn_closer' onClick={mainBannerClose}/>
                        </div>
                    </div>
                }
                    <div className='Chapter1_body_contents'>
                        <div className='Chapter1_body_contents_left'>
                            <React.Fragment>
                                <div className='Chapter1_body_contents_left_head'>
                                    <div className= 'Chapter1_title_text'>
                                                        세상 모든 컨텐츠,<br />
                                                        여기 다 모였다!<br />
                                                        하루가 소중한 당신에게!<br />
                                                        필요한 뉴스정보만!
                                    </div>
                                </div>
                            </React.Fragment>
                                <div style={{ marginBottom: '285px' }}>
                                    <div style={{ height: '82px' }}></div>
                                    <div className='Chapter1_button'>
                                        <img srcSet='/images/body/googleplay_download_normalx1.png 1920w, /images/body/googleplay_download_normalx2.png 2000w, /images/body/googleplay_download_normalx3.png 3000w'  alt='btn_google' className='Chapter1_btn_google_m' onClick={googleapp}/>
                                        <div style={{ width: '16px' }}></div>
                                        <img  srcSet='/images/body/appstore_download_normalx1.png 1920w, /images/body/appstore_download_normalx2.png 2000w, /images/body/appstore_download_normalx3.png 3000w'  alt='btn_google' className='Chapter1_btn_google_m' onClick={appleapp}/>
                                    </div>
                                </div>
                        </div> 
                        <div className='Chapter1_body_contents_right'>
                            <div className='Chapter1_body_contents_right_head'>
                                <div>
                                    <img  srcSet='/images/body/Chapter1_right_topx1.png 1920w, /images/body/Chapter1_right_topx2.png 2000w, /images/body/Chapter1_right_topx3.png 3000w' alt='img_mobile' className='Chapter1_img_head_main' />
                                </div>
                            </div>  
                            <div className='Chapter1_body_contents_right_cont'>
                                <div>
                                    <img srcSet='/images/body/Chapter1_right_bottomx1.png 1920w, /images/body/Chapter1_right_bottomx2.png 2000w, /images/body/Chapter1_right_bottomx3.png 3000w'  alt='img_mobile' className='Chapter1_img_cont_main' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
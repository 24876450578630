import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({

}));

const Chapter1 = () => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.background_embed}>
                <div className={classes.mouseAni}>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Chapter1;
import { observable, action } from 'mobx';
import { LARGE,LIGHT } from '../../common/constants'

export default class LayoutStore {

    @observable sizeMode = LARGE;
    @observable mode = LIGHT;

    @observable banner = true;
   

    constructor(root) {
        this.root = root;
        this.mode = localStorage.getItem("ROZEUS_IO_VIEW_MODE") ? localStorage.getItem("ROZEUS_IO_VIEW_MODE") : LIGHT;
    }

    @action setSizeMode = (val) => {
        this.sizeMode = val;
    }

    @action setBanner = (val) => {
        this.banner = val;
    }
}
import React from 'react';
import { inject, observer } from 'mobx-react';
import makeStyles from '@mui/styles/makeStyles';
import './Privacymodal.css';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width : '100%',
        height: '100%',
    },
    body: {
        width : '100%',
        height: '100%',
        justifyContent:'space-between',
        alignItems:'center',
        margin: 'auto',
        bgcolor: 'background.paper',
        backgroundColor : 'white',
        border: '1px solid #ECEEF2',
    },
}));

const Privacymodal = ({ layout }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}> 
                <div className='small_privacymodal_text_center small_privacymodal_title'>
                    서비스 이용 약관
                </div>
                <div style={{ height: '30px' }} />
                <div className='small_privacymodal_text_center small_privacymodal_sub_title'>
                    프로필 정보는 로제우스 서비스의 맞춤형 컨텐츠 제공 및 팔로워·멤버 추천에 활용되며,<br />
                    회원 탈퇴 또는 동의 철회시 즉시 파괴됩니다.
                </div>
                <div style={{ height: '30px' }} />
                <div className='small_privacymodal_body_contents '>
                    <div className='small_privacymodal_body_left_contents'>
                        <div className='small_privacymodal_title_left_text'>
                            목적
                        </div>
                        <div className='small_privacymodal_title_left_sub_text'>
                            서비스 기본 기능 제공 (사용자간 소셜 활동 포함)을 위한 이용자 식별 및 회원관리
                        </div>
                    </div>
                    <div className='small_privacymodal_body_right_contents'>
                        <div className='small_privacymodal_title_right_text'>
                            항목
                        </div>
                        <div className='small_privacymodal_title_right_sub_text'>
                            휴대폰 번호, 이용자 이름(닉네임), 프로필 사진
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Privacymodal));
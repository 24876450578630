import { createTheme, adaptV4Theme } from '@mui/material';

export const largeLight = createTheme(adaptV4Theme({
    palette: {
        background: {
            default: "#ffffff",
            paper: "#ffffff",
            footer:'#f8f8f8',
            sub:{
                chapCard: '#ffffff',
                subBackground:'#FFF9F9',
                chapBack: '#CD4D4D',
                tabHover:'#FFFDFD'
            }
        },
        text: {
            primary: "#1e1e1e",
            secondary:'#ED1C24',
            normal:'#5d5d5d',
            highlight:'#1e1e1e',
            footer: '#ED1C24',
            footerCaption:'#333333',
            sub:{
                primary: '#ED1C24',
                sub1:'#1e1e1e',
                sub2:'#333333',
                sub3: '#CD4D4D'
            },
        },
        primary:{
            main:'#ffffff',
        },
        action :{
            hover: '#F8F8F8'
        },
        button:{
            normal:'#6E6AD2',
            hover:'#CD4D4D',
            active: '#B13F3F',
            hoverText:'#ffffff',
            text : '#FFFFFF'
        },
        button2: {
            normal:'#ED1C24',
            hover: '#DE1B22',
            active: '#CC161D',
            text: '#ffffff'
        },
        divider: '#ECECEC',
        menuUnderLine: '#1E1E1E',
        footerDivider: '#E2E2E2',
        roadmapPrimary: '#CD4D4D',
        subBox: '#F0D6D6',
        circleBorder: '#DFDFDF',
        subCard: '#ffffff',
        divider2: '#1E1E1E',
        linkBox: '#F0D6D6',
        inputBorder:'#5D5D5D',
        inputBorderHover: '#DF8686',
        inputBorderFocus: '#ED1C24'

    },
    typography: {
        h1:{
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '32px',
            lineHeight: '46px',
        },
        h2: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '20px',
            lineHeight: '26px',
        },
        h3: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '14px',
            lineHeight: '22px',
        },
        h4: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '50px',
            lineHeight: '66px',
        },
        h5: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '30px',
            color: '#5d5d5d',
            "&:hover": {
                color: '#1e1e1e',
            },
        },
        subtitle1: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '52px',
            lineHeight: '58px',
        },
        subtitle2: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '22px',
            lineHeight: '40px',
        },
        body1: {
            fontFamily: 's-core_dream3_light',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '30px',
            color:'#333333'
        },
        body2: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '28px',
        },
        h6: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '288px',
            lineHeight:'240px',
            color:'#F6F4F3',
            marginLeft: '-11px',
        },
        overline: {
            cursor: 'pointer',
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '28px',
            "&:hover": {
                borderBottom:'1.5px solid #1e1e1e'
            }
        },
    },
}));


export const largeLightChina = createTheme(adaptV4Theme({
    palette: {
        background: {
            default: "#ffffff",
            paper: "#ffffff",
            footer:'#f8f8f8',
            sub:{
                chapCard: '#ffffff',
                subBackground:'#FFF9F9',
                chapBack: '#CD4D4D',
                tabHover:'#FFFDFD'
            }
        },
        text: {
            primary: "#1e1e1e",
            secondary:'#ED1C24',
            normal:'#5d5d5d',
            highlight:'#1e1e1e',
            footer: '#ED1C24',
            footerCaption:'#333333',
            sub:{
                primary: '#ED1C24',
                sub1:'#1e1e1e',
                sub2:'#333333',
                sub3: '#CD4D4D'
            },
        },
        primary:{
            main:'#ffffff',
        },
        action :{
            hover: '#F8F8F8'
        },
        button:{
            hover:'#CD4D4D',
            active: '#B13F3F',
            hoverText:'#ffffff'
        },
        button2: {
            normal:'#ED1C24',
            hover: '#DE1B22',
            active: '#CC161D',
            text: '#ffffff'
        },
        divider: '#ECECEC',
        menuUnderLine: '#1E1E1E',
        footerDivider: '#E2E2E2',
        roadmapPrimary: '#CD4D4D',
        subBox: '#F0D6D6',
        circleBorder: '#DFDFDF',
        subCard: '#ffffff',
        divider2: '#1E1E1E',
        linkBox: '#F0D6D6',
        inputBorder:'#5D5D5D',
        inputBorderHover: '#DF8686',
        inputBorderFocus: '#ED1C24'

    },
    typography: {
        h1:{
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '32px',
            lineHeight: '46px',
        },
        h2: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '26px',
        },
        h3: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '22px',
        },
        h4: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '50px',
            lineHeight: '66px',
        },
        h5: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '30px',
            color: '#5d5d5d',
            "&:hover": {
                color: '#1e1e1e',
            },
        },
        subtitle1: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '52px',
            lineHeight: '58px',
        },
        subtitle2: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '22px',
            lineHeight: '40px',
        },
        body1: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '30px',
            color:'#333333'
        },
        body2: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '28px',
        },
        h6: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '288px',
            lineHeight:'240px',
            color:'#F6F4F3',
            marginLeft: '-11px',
        },
        overline: {
            cursor: 'pointer',
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '28px',
            "&:hover": {
                borderBottom:'1.5px solid #1e1e1e'
            }
        },
    },
}));



export const largeDark = createTheme(adaptV4Theme({
    palette: {
        background: {
            default: "#202020",
            paper: "#2B2B2B",
            footer: '#181818',
            sub: {
                chapCard: '#232323',
                subBackground: '#27262B',
                chapBack: '#5E4CA0',
                tabHover: '#232425'
            }
        },
        text: {
            primary: "#ffffff",
            secondary: '#8C6FF1',
            normal: '#C6C6C6',
            highlight: '#ffffff',
            footer: '#A792F3',
            footerCaption: '#F9F9F9',
            sub: {
                primary: '#8C6FF1',
                sub1: '#ffffff',
                sub2: '#cfcfcf',
                sub3: '#5E4CA0'
            }
        },
        primary: {
            main: '#202020',
        },
        action: {
            hover: '#353535'
        },
        button: {
            hover: '#8C6FF1',
            active: '#6B52C8',
            hoverText: '#ffffff',
        },
        button2: {
            normal: '#8C6FF1',
            hover: '#7D60E6',
            active: '#6B52C8',
            text: '#ffffff'
        },
        divider: '#333333',
        menuUnderLine: '#ffffff',
        footerDivider: '#414141',
        roadmapPrimary: '#8C6FF1',
        subBox: '#646464',
        circleBorder: '#646464',
        subCard : '#1A1A1A',
        divider2: '#cfcfcf',
        linkBox: '#4D4D4E',
        inputBorder: '#202020',
        inputBorderHover: '#5E4CA0',
        inputBorderFocus: '#8C6FF1'
    },
    typography: {
        h1: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '32px',
            lineHeight: '46px',
        },
        h2: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '20px',
            lineHeight: '26px',
        },
        h3: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '14px',
            lineHeight: '22px',
        },
        h4: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '50px',
            lineHeight: '66px',
        },
        h5: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '30px',
            color: '#C6C6C6',
            "&:hover": {
                color: '#ffffff',
            },
        },
        subtitle1: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '52px',
            lineHeight: '58px',
        },
        subtitle2: {
            fontFamily: 's-core_dream4_regular',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '22px',
            lineHeight: '40px',
        },
        body1: {
            fontFamily: 's-core_dream3_light',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '30px',
            color:'#CFCFCF',
        },
        body2: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '28px',
        }, 
        h6: {
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '288px',
            lineHeight: '240px',
            color: '#2E2E2E',
            marginLeft: '-11px',
        },
        overline: {
            cursor:'pointer',
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '28px',
            "&:hover": {
                borderBottom: '1.5px solid #ffffff'
            }
        },
    }
}));

export const largeDarkChina = createTheme(adaptV4Theme({
    palette: {
        background: {
            default: "#202020",
            paper: "#2B2B2B",
            footer: '#181818',
            sub: {
                chapCard: '#232323',
                subBackground: '#27262B',
                chapBack: '#5E4CA0',
                tabHover: '#232425'
            }
        },
        text: {
            primary: "#ffffff",
            secondary: '#8C6FF1',
            normal: '#C6C6C6',
            highlight: '#ffffff',
            footer: '#A792F3',
            footerCaption: '#F9F9F9',
            sub: {
                primary: '#8C6FF1',
                sub1: '#ffffff',
                sub2: '#cfcfcf',
                sub3: '#5E4CA0'
            }
        },
        primary: {
            main: '#202020',
        },
        action: {
            hover: '#353535'
        },
        button: {
            hover: '#8C6FF1',
            active: '#6B52C8',
            hoverText: '#ffffff',
        },
        button2: {
            normal: '#8C6FF1',
            hover: '#7D60E6',
            active: '#6B52C8',
            text: '#ffffff'
        },
        divider: '#333333',
        menuUnderLine: '#ffffff',
        footerDivider: '#414141',
        roadmapPrimary: '#8C6FF1',
        subBox: '#646464',
        circleBorder: '#646464',
        subCard : '#1A1A1A',
        divider2: '#cfcfcf',
        linkBox: '#4D4D4E',
        inputBorder: '#202020',
        inputBorderHover: '#5E4CA0',
        inputBorderFocus: '#8C6FF1'
    },
    typography: {
        h1: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '32px',
            lineHeight: '46px',
        },
        h2: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '26px',
        },
        h3: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '22px',
        },
        h4: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '50px',
            lineHeight: '66px',
        },
        h5: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '30px',
            color: '#C6C6C6',
            "&:hover": {
                color: '#ffffff',
            },
        },
        subtitle1: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '52px',
            lineHeight: '58px',
        },
        subtitle2: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '22px',
            lineHeight: '40px',
        },
        body1: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '30px',
            color:'#CFCFCF',
        },
        body2: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '28px',
        }, 
        h6: {
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '288px',
            lineHeight: '240px',
            color: '#2E2E2E',
            marginLeft: '-11px',
        },
        overline: {
            cursor:'pointer',
            fontFamily: 'Noto Sans SC',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '28px',
            "&:hover": {
                borderBottom: '1.5px solid #ffffff'
            }
        },
    }
}));

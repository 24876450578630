import React, { useEffect , useState } from 'react';
import { m_size } from '../../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { post } from 'axios';
import makeStyles from '@mui/styles/makeStyles';
import { Typography , Dialog } from '@mui/material';
import { inject, observer } from 'mobx-react';
import CustomButton from '../../../components/common/CustomButton';
import CustomButton2 from '../../../components/common/CustomButton2';
import './Chapter5.css'

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '80% auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
    },
    input:{
        padding: '14px 14px',
        background: '#FFFFFF',
        border: '1px solid #E1E1E1',
        borderRadius: '2px',
        fontFamily: 'paybooc',
        fontSize: '16px',
        '&:hover': {
            border: '1px solid #E1E1E1',
        },
        '&:focus' :{
            outline:'none',
            border: '1px solid #E1E1E1',
        },
        '&::placeholder': {
            color:'#868686'
        }
    },
    dialogBody:{
        width: '280px', 
        height: '200px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
    },
}));

const Chapter5 = ({ layout }) => {

    const classes = useStyles(layout);
    const [msg, setMsg] = useState({});
    const [msgPop, setMsgPop] = useState(false);
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [bChecked, setChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState(new Set());

    const handleChange = (e) => {

        switch(e.target.name){
            case 'name':
                setName(e.target.value);
                return;
            case 'email':
                setEmail(e.target.value);
                return;
            case 'message':
                setMessage(e.target.value);
                return;
            case 'title':
                setTitle(e.target.value);
                return;
            case 'tel':
                setTel(e.target.value);
                return;
            default:
                return;
        }
    }
    const handleClick = () => {

        var check = true;
        if(bChecked===false){
            check = false;
            setMsg({ title: '전송실패', text:'개인정보 수집 및 이용에 동의해주세요.'});
        }


        if (message.length < 2) {
            check = false;
            if (message.length === 0) {
                setMsg({ title:'전송실패',text:'Message을 입력해주세요'});
            } else {
                setMsg({ title: '전송실패', text:'Message는 두글자 이상 입력해주세요'});
            }
        }

        if (title.length < 2) {
            check = false;
            if (name.length === 0) {
                setMsg({ title: '전송실패', text:'제목을 입력해주세요.'});
            } else {
                setMsg({ title: '전송실패', text:'제목은 두글자 이상 입력해 주세요.'});
            }
        }

        if (email.length === 0) {
            check = false;
            setMsg({ title: '전송실패', text:'이메일을 입력해주세요'});
        } else {
            const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
            if (!reg_email.test(email)) {
                check = false;
                setMsg({ title: '전송실패', text:'올바르지 않은 메일 형식입니다.'})
            }
        }

        if (tel.length < 2) {
            check = false;
            if (name.length === 0) {
                setMsg({ title: '전송실패', text:'전화번호를 입력해주세요'});
            } else {
                setMsg({ title: '전송실패', text:'올바르지 않은 전화번호 형식입니다'});
            }
        }


        if (name.length < 2) {
            check = false;
            if (name.length === 0) {
                setMsg({ title: '전송실패', text:'이름을 입력해주세요.'});
            } else {
                setMsg({ title: '전송실패', text:'이름은 두글자 이상 입력해주세요'});
            }
        }

        let question = "이름 ="+name+"\r\n전화번호="+tel+"\r\n이메일="+email+"\r\n문의내용="+message+"\r\n"

        if(check){
            console.log('SEND SERVER')
            const config = {
                headers: {
                    'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                    "Access-Control-Allow-Origin" : "*",
                    "Content-Type" : "application/json"
                },
            }         
            post('https://api.rozeus.com/admin/api/v1/cs/qnas/service' , {"reg_id" :'0', "qna_tp" : '2' , "title" : title , "question" :question }, config)
                .then((response) => {
                    setMsg({ title: '전송완료', text: '작성하신 내용이 전송완료되었습니다.' });
                    setMsgPop(true);
                }).catch(err => {
                    return null;
                });
        }else{
            setMsgPop(true);
        }
    }
    const handleClose = () =>{
        setMsgPop(false);
    }


    const checkHandler = ({ target }) => {
        setChecked(!bChecked);
        checkedItemHandler(Chapter5.id, target.checked);
    };

    const checkedItemHandler = (id, isChecked) => {
        if (isChecked) {
            checkedItems.add(id);
            setCheckedItems(checkedItems);
        } else if (!isChecked && checkedItems.has(id)) {
            checkedItems.delete(id);
            setCheckedItems(checkedItems);
        }
    };
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'80px'}}></div>
                <div className= 'small_Chapter5_title_header_first_text'>
                                                서비스/제휴 문의
                </div>
                <div style={{height:'60px'}}></div>
                <div className='small_Chapter5_title_body'>
                    <Typography variant='body2' className='small_Chapter5_body_title'>이름</Typography>
                    <input type='text' name='name' className={classes.input} placeholder='이름을 입력해주세요.' style={{ height:'50px' ,  width: '93%'}} onChange={handleChange}/>
                    <div style={{height:'20px'}}></div>
                    <Typography variant='body2' className='small_Chapter5_body_title'>전화번호</Typography>
                    <input type='text' name='tel' className={classes.input} placeholder='전화번호를 입력해주세요.' style={{ height:'50px' ,  width: '93%'}} onChange={handleChange}/>
                    <div style={{height:'20px'}}></div>
                    <Typography variant='body2' className='small_Chapter5_body_title'>이메일 주소</Typography>
                    <input type='text' name='email' className={classes.input} placeholder='이메일 주소를 입력해주세요.' style={{ height:'50px' ,  width: '93%'}} onChange={handleChange}/>
                    <div style={{height:'20px'}}></div>
                    <Typography variant='body2' className='small_Chapter5_body_title'>제목</Typography>
                    <input type='text' name='title' className={classes.input} placeholder='제목을 입력해주세요.' style={{ height:'50px' ,  width: '93%'}} onChange={handleChange}/>
                    <div style={{height:'20px'}}></div>
                    <Typography variant='body2' className='small_Chapter5_body_title'>문의내용</Typography>
                    <textarea name='message' className={classes.input} placeholder='문의내용을 입력해주세요.' style={{ resize: 'vertical', minHeight: '220px', width:'93%'}} onChange={handleChange}/>
                    <div style={{height:'20px'}}></div>
                    <div className='small_Chapter5_body_service'>
                        <div style={{ flexGrow:1}}>
                            <div className='small_Chapter5_body_service_title'>
                                ● 
                            </div>
                        </div>
                        <div style={{ flexGrow:10}}>
                            <div className='small_Chapter5_body_service_title'>
                                비즈니스 제휴를 목적으로 수집 및 이용 등 처리에 있어 아래 사항을 확인해주세요
                            </div>
                        </div>
                    </div>
                    <div style={{height:'12px'}} />
                    <div className = 'small_Chapter5_body_bottom_box'>
                        <div style={{height:'18px'}} />
                        <div className= 'small_Chapter5_body_bottom_title'>
                                            1.개인정보 수집 및 이용 목적
                        </div>
                        <div style={{height:'4px'}} />
                        <div className= 'small_Chapter5_body_bottom_cont'>
                                            제품 소개, 파트너쉽 요청 상담 등 고객 문의 처리
                        </div> 
                        <div style={{height:'16px'}} />
                        <div className= 'small_Chapter5_body_bottom_title'>
                                            2.수집하는 개인정보의 항목
                        </div>
                        <div style={{height:'4px'}} />
                        <div className= 'small_Chapter5_body_bottom_cont'>
                                            필수 항목: 회사이름, 이메일 주소, 전화번호
                        </div>     
                        <div style={{height:'16px'}} />
                        <div className= 'small_Chapter5_body_bottom_title'>
                                            3.개인정보 보유 및 이용기간
                        </div>
                        <div style={{height:'4px'}} />
                        <div className= 'small_Chapter5_body_bottom_cont'>
                                            목적 당성 후 90일까지 보관 후 파기
                        </div>
                        <div style={{height:'20px'}} />
                    </div>
                    <div style={{height:'20px'}} />
                    <div className='small_Chapter5_bottom_service'>
                        <div style={{ flexGrow:1}}>
                            <div className='small_Chapter5_bottom_service_title'>
                                <input type='checkbox'  className='small_Chapter5_bottom_service_title_check'  checked={bChecked} onChange={(e) => checkHandler(e)}/>
                            </div>
                        </div>
                        <div style={{ flexGrow:5}}>
                            <div className='small_Chapter5_bottom_service_cont'>
                                제휴를 목적으로 개인정보를 수집 및 이용함에 동의합니다.
                            </div>
                        </div>
                    </div>
                    <div style={{height:'20px'}} />
                    <div style={{width:'100%'}}>
                                        <CustomButton
                                            text='문의하기'
                                            size={{ width: '100%', height: '50px'}}
                                            onclick={handleClick} />
                    </div>
                </div>
                <div style={{ height: '40px' }} />
                <img 
                    srcSet='/images/small/body/small_Chapter5_mapx1.png 214w, /images/small/body/small_Chapter5_mapx2.png 414w, /images/small/body/small_Chapter5_mapx3.png 614w' 
                    alt='Chapter5_frist_img' 
                    className='Chapter5_frist_img'
                />
                <div style={{height:'22px'}} />
                <div className='small_Chapter5_bottom_service'>
                    <div style={{ flexGrow:1}}>
                        <div className='small_Chapter5_icon_email'>
                            <img src='/images/small/body/Chapter5_icon_email.png' alt='small_Chapter5_icon_email' className='small_Chapter5_icon_email'/>
                        </div>
                    </div>
                    <div style={{ flexGrow:18}}>
                        <div className='small_Chapter5_bottom_service_bottom_title'>
                            E-mail
                        </div>
                        <div className='small_Chapter5_bottom_service_bottom_cont'>
                            pr@rozeus.com
                        </div>
                    </div>
                </div>
                <div style={{height:'12px'}} />
                <div className='small_Chapter5_bottom_service'>
                    <div style={{ flexGrow:1}}>
                        <div className='small_Chapter5_icon_adress'>
                            <img src='/images/small/body/Chapter5_icon_adress.png' alt='small_Chapter5_icon_adress' className='small_Chapter5_icon_adress'/>
                        </div>
                    </div>
                    <div style={{ flexGrow:7}}>
                        <div className='small_Chapter5_bottom_service_bottom_title'>
                            Adress
                        </div>
                        <div className='small_Chapter5_bottom_service_bottom_cont'>
                            서울특별시 중구 퇴계로 385(흥인동 132) 준타워 8층
                        </div>
                    </div>
                </div>
                <div style={{ height: '60px' }} />
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={msgPop}>
                    <div style={{ height: '40px' }} />
                        <div className={classes.dialogBody}>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-between', height:'100%'}}>
                                <Typography variant='subtitle2'>{msg.title}</Typography>
                                <div style={{ height: '70px' }} />
                                    <Typography variant='body2' style={{ flexGrow:1, fontFamily: 's-core_dream4_regular' }} >{msg.text}</Typography>
                                    <CustomButton2
                                        text='확인'
                                        size={{ width: '120px', height: '44px' }}
                                        onclick={handleClose} />
                                </div>
                        </div>
                    <div style={{ height: '30px' }} />
                </Dialog>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter5));
import React  from 'react';
import { Fade } from '@mui/material';
import { inject, observer } from 'mobx-react';

const HomeTemplate = ({ main, chapter1, chapter2, chapter3, chapter4, chapter5, chapter6, chapter7,  marginTab, menu }) => {
    return (
        <Fade in={true} timeout={600}>
            <div onClick={menu.deselect}>
                <div id='top'>
                    {main}
                </div>
                {chapter1}
                {chapter2}
                {chapter3}
                {chapter4}
                {chapter5}
                {chapter6}
                {chapter7}
                {marginTab}
            </div>
        </Fade>
    );
};

export default inject(({ menu }) => ({
    menu: menu
}))(observer(HomeTemplate));
import React, { useEffect } from 'react';
import { size , aos_option } from '../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import './Chapter4.css';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1560px',
        margin: 'auto'
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '1098px',
        margin:'auto'
    },
    contentWrap: {
        position: 'absolute', 
        height: '100%',
        width: '100%'
    },
}));

const Chapter4 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div>
                <img srcSet='/images/body/Chapter4_main_bgx1.png 1920w, /images/body/Chapter4_main_bgx2.png 2000w, /images/body/Chapter4_main_bgx3.png 3000w'  alt='img_mobile' className='Chapter4_img_main' />
            </div>
            <div className={classes.body}>
                <div className={classes.contentWrap}>
                <div className='Chapter4_body_contents'>
                        <div className='Chapter4_body_contents_left'>
                            <React.Fragment>
                                <div className='Chapter4_body_contents_left_head'>
                                    <div>
                                        <img 
                                            data-aos='fade-up' 
                                            data-aos-duration={aos_option.duration} 
                                            data-aos-offset={aos_option.offset}                                 
                                            srcSet='/images/body/Chapter4_img_top_first_left_Framex1.png 1920w, /images/body/Chapter4_img_top_first_left_Framex2.png 2000w, /images/body/Chapter4_img_top_first_left_Framex3.png 3000w' 
                                            alt='img_mobile' 
                                            className='Chapter4_img_top_first_left' 
                                        />
                                    </div>
                                    <div>
                                        <img 
                                            data-aos='fade-up' 
                                            data-aos-duration={aos_option.duration} 
                                            data-aos-offset={aos_option.offset}
                                            srcSet='/images/body/Chapter4_img_top_first_left_main_imgx1.png 1920w, /images/body/Chapter4_img_top_first_left_main_imgx2.png 2000w, /images/body/Chapter4_img_top_first_left_main_imgx3.png 3000w' 
                                            alt='img_mobile' 
                                            className='Chapter4_img_top_second_left' 
                                        />
                                    </div>
                                </div>
                                <div 
                                    data-aos='fade-up' 
                                    data-aos-duration={aos_option.duration} 
                                    data-aos-offset={aos_option.offset}      
                                    className='Chapter4_body_contents_right_cont'>
                                    <div className= 'Chapter4_title_left_first_text'>
                                                        생활정보
                                    </div>
                                    <div style={{ height: '22px' }} />
                                    <div className= 'Chapter4_title_left_second_text'>
                                                        내 생활 속에 쏙!<br />
                                                        생활 밀착형 뉴스를 한번에!
                                    </div>
                                    <div style={{ height: '16px' }} />
                                    <div className= 'Chapter4_title_left_third_text'>
                                                        로제우스에선 코로나현황, 날씨, 금융 뉴스를 한눈에 확인 가능!<br />
                                                        아직도 코로나와 금융 뉴스는 네이버, 날씨는 다음에서 찾으시나요?<br />
                                                        로제우스에서는 한번에 쉽게 확인 가능해요!<br />
                                                        뿐만 아니라, 다른 곳에서 제공하지 않는 실시간 거리두기 현황과<br />
                                                        내 주식 및 가상화폐의 가격변동까지 확인할 수 있어요
                                    </div>
                                </div>
                            </React.Fragment>
                        </div> 
                        <div className='Chapter4_body_contents_right'>
                            <React.Fragment>
                                <div 
                                    data-aos='fade-up' 
                                    data-aos-duration={aos_option.duration} 
                                    data-aos-offset={aos_option.offset}                                          
                                    className='Chapter4_body_contents_right_head'>
                                    <div className= 'Chapter4_title_right_first_text'>
                                                            뉴스룸
                                    </div>
                                    <div style={{ height: '22px' }} />
                                    <div className= 'Chapter4_title_right_second_text'>
                                                            작은 소식을<br />
                                                            큰 이야기로 만들어보세요!
                                    </div>
                                    <div style={{ height: '16px' }} />
                                    <div className= 'Chapter4_title_right_third_text'>
                                                            뉴스뿐만아니라 세상의 모든 컨텐츠를 모아서 이야기 할 수 있어요<br />
                                                            전문적인 소통과 깊이 있는 정보를 찾는다면, 공식 뉴스룸[팔로우] 클릭!<br />
                                                            취향과 관심사가 같은 사람들과 소통을 원한다면,관심사 뉴스룸[팔로우] 클릭!<br />
                                                            내가 직접 포스트를 작성하고, 다른 사람들의 포스트를 리포스트하며<br />
                                                            다양한 생각을 공유하세요
                                    </div>
                                    <div style={{ height: '12px' }} />
                                    <div className= 'Chapter4_title_right_fourth_text'>
                                                            나만의 공간 "개인 뉴스룸"<br />
                                                            함께하면 더 즐거운 공간 "커뮤니티 뉴스룸"
                                    </div>
                                </div>
                                <div className='Chapter4_body_contents_right_cont_bottom'>
                                    <div>
                                        <img 
                                            data-aos='fade-up' 
                                            data-aos-duration={aos_option.duration} 
                                            data-aos-offset={aos_option.offset}                                        
                                            srcSet='/images/body/Chapter4_img_top_first_right_Framex1.png 1920w, /images/body/Chapter4_img_top_first_right_Framex2.png 2000w, /images/body/Chapter4_img_top_first_right_Framex3.png 3000w' 
                                            alt='img_mobile' 
                                            className='Chapter4_img_top_first_right' 
                                        />
                                    </div>
                                    <div>
                                        <img 
                                            data-aos='fade-up' 
                                            data-aos-duration={aos_option.duration} 
                                            data-aos-offset={aos_option.offset}                                        
                                            srcSet='/images/body/Chapter4_img_top_first_right_main_imgx1.png 1920w, /images/body/Chapter4_img_top_first_right_main_imgx2.png 2000w, /images/body/Chapter4_img_top_first_right_main_imgx3.png 3000w' 
                                            alt='img_mobile' 
                                            className='Chapter4_img_top_second_right' 
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter4));
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { themeType } from './common/constants';

import MainContainer from './containers/MainContainer';

import { largeLight} from './CssTheme'
import { smallLight} from './SmallCssTheme'


const App = ({ layout }) => {
  
  const [theme, setTheme] = useState(largeLight);
  useEffect(() => {
    const selectTheme = layout.sizeMode+'_'+layout.mode;
    switch (selectTheme){
      case themeType.LARGE_LIGHT:
        setTheme(largeLight);
        return;
      case themeType.SMALL_LIGHT:
        setTheme(smallLight);
        return;
      default : 
        setTheme(largeLight)
    }
  
  }, [layout.sizeMode, layout.mode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MainContainer  />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default inject(({ layout }) => ({
  layout: layout
}))(observer(App));

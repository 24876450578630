import React, { useEffect } from 'react';
import { size , aos_option } from '../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import './Chapter2.css';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1062px',
        margin: 'auto',
        fontFamily: 'paybooc',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 133px',
        backgroundSize: '600px 496.91px'
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '1062px',
        margin:'auto'
    },
    contentWrap: {
        position: 'absolute', 
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '100%',
    },
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
            <div className= 'Chapter2_title_text'>
                            놓치면 안되는<br />
                            모든 뉴스정보를 보여드려요
            </div>
                <div className={classes.contentWrap}>
                <div style={{ height: '50px' }} />
                <div className='Chapter2_body_contents'>
                        <div 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset}                            
                            className='Chapter2_body_contents_left'>
                                <div className='Chapter2_body_contents_left_head'>
                                    <img
                                        srcSet='/images/body/Chapter2_left_topx1.png 1920w, /images/body/Chapter2_left_topx2.png 2000w, /images/body/Chapter2_left_topx3.png 3000w'
                                        alt='img_mobile' 
                                        className='Chapter2_img_left_head_main' 
                                    />
                                </div>
                                <div style={{ height: '34px' }} />
                                <div className='Chapter2_body_contents_left_cont'>
                                    <div                                   
                                        className= 'Chapter2_m_title_left_first_text'>
                                                            네이버에 없는 뉴스,<br />
                                                            여기 다 있다!
                                    </div>
                                    <div style={{ height: '16px' }} />
                                    <div className= 'Chapter2_m_title_left_second_text'>
                                                            국내뉴스 99%제공!<br />
                                                            다른곳에서는 볼 수 없는 로얄티 언로사부터 실시간<br />
                                                            속보, 정치, 경제, 연예, 일상 뉴스를 한번에
                                    </div>
                                </div>                              
                        </div> 
                        <div style={{ marginLeft: '10px' }} />
                        <div className='Chapter2_body_contents_center'>
                                <div style={{ height: '120px' }} />
                                <div 
                                    data-aos='fade-up' 
                                    data-aos-duration={aos_option.duration} 
                                    data-aos-offset={aos_option.offset}                                  
                                >
                                    <div className='Chapter2_body_contents_center_head'>
                                        <img 
                                            srcSet='/images/body/Chapter2_center_topx1.png 1920w, /images/body/Chapter2_center_topx2.png 2000w, /images/body/Chapter2_center_topx3.png 3000w'
                                            alt='img_mobile' 
                                            className='Chapter2_img_center_head_main' 
                                        />
                                    </div>  
                                    <div style={{ height: '34px' }} />
                                    <div className='Chapter2_body_contents_center_cont'>
                                    <div className= 'Chapter2_m_title_center_first_text'>
                                                                    최신 트렌드에 뒤쳐지지 않도록<br />
                                                                    요약은 저희가,읽기만 하세요      
                                    </div>
                                    <div style={{ height: '16px' }} />
                                    <div className= 'Chapter2_m_title_center_second_text'>
                                                                    실시간 뉴스부터, 이 시각 놓치면 아쉬운 뉴스를<br />
                                                                    보여 드려요 그날의 헤드라인부터 최신뉴스까지<br />
                                                                    모두 놓치지 마세요.<br />
                                                                    최신 트렌드는 로제우스에서!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: '30px' }} />
                        <div className='Chapter2_body_contents_right'>
                            <div style={{ height: '180px' }} />
                            <div 
                                    data-aos='fade-up' 
                                    data-aos-duration={aos_option.duration} 
                                    data-aos-offset={aos_option.offset}                                  
                            >
                                <div className='Chapter2_body_contents_right_head'>
                                        <img 
                                            srcSet='/images/body/Chapter2_right_topx1.png 1920w, /images/body/Chapter2_right_topx2.png 2000w, /images/body/Chapter2_right_topx3.png 3000w'
                                            alt='img_mobile' 
                                            className='Chapter2_img_right_head_main' 
                                        />
                                </div>  
                                <div style={{ height: '34px' }} />
                                <div className='Chapter2_body_contents_right_cont'>
                                    <div className= 'Chapter2_m_title_right_first_text'>
                                                                    나에게 꼭 맞춰진 맞춤 카드<br />
                                                                    무작위 추천은 이제 그만!      
                                    </div>
                                    <div style={{ height: '16px' }} />
                                    <div className= 'Chapter2_m_title_right_second_text'>
                                                                    보고 싶은 뉴스, 놓치고 싶지 않은 뉴스를 로제우스가<br />
                                                                    챙겨드릴게요. 간편하게 나만의 맞춤 카드 등록으로<br />
                                                                    보고싶은 뉴스만 쏙! <br />
                                    </div>
                                    <div style={{ height: '10px' }} />
                                    <div className= 'Chapter2_m_title_right_third_text'>
                                                                    #탈모
                                                                    <div className='Chapter2_m_title_right_third_sub_text'>
                                                                        and
                                                                    </div>                                                               
                                                                    영양제 #도쿄올림픽 #백신
                                                                    <div className='Chapter2_m_title_right_third_sub_text'>
                                                                        or
                                                                    </div>
                                                                    백신예약
                                    </div>
                                    <div className= 'Chapter2_m_title_right_third_text'>
                                                                    #정치 #경제 #문화 #연예/오락 #기타    
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
import LayoutStore from "./common/layout";
import MenuStore from "./component/menu";

class RootStore {
    constructor() {
        this.layout = new LayoutStore(this);
        this.menu = new MenuStore(this);
    }
}

export default RootStore;
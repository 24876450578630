import React  from 'react';
import { useScrollTrigger, Zoom } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import { Route  } from 'react-router-dom';
import HomeContainer from '../containers/HomeContainer';

const useStyles = makeStyles(theme => ({
    fontCustom: {
        fontSize: '18px'
    },
    notchedOutline: {
        borderWidth: '2px',
        borderColor: '#e7484e !important',
    },
    scrollTopFab: {
        position: "fixed",
        bottom: '9.6%',
        left: '81%',
        zIndex:1000,
    }
}));

function ScrollTop(props) {

    const { children } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = event => {

        const anchor = (event.target.ownerDocument || document).querySelector(
            "#back-to-top-anchor"
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        <div className={classes.scrollTopFab}>
            <Zoom in={trigger}>
                <div onClick={handleClick} role="presentation" >
                    {children}
                </div>
            </Zoom>
        </div>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired
};

const MainTemplate = ({ header, footer, headerSize}) => {
    return (
        <React.Fragment>
            {header}
            <div style={{ height: headerSize+'px' }}></div>
            <Route path="/" exact>
                <HomeContainer />
                {footer}
            </Route>
            <ScrollTop>
                <img src='/images/common/floating_btn.png' alt='Go to Top' style={{ width: '56px', height: '56px' }}/>
            </ScrollTop>
        </React.Fragment>
    );
};

export default MainTemplate;
import { observable, action } from 'mobx';

export default class MenuStore {

    @observable target = null;
    @observable subMenuObj = null;

    @observable smallMenuShow = false;

    constructor(root) {
        this.root = root;
        this.target = null
        this.subMenuObj = null
    }

    @action select = (name, val) => {
        this.target = name;
        this.subMenuObj = val;
        // console.log(1,name, val)
    }

    @action deselect = () => {
        this.target = null
        this.subMenuObj = null
        // console.log(2,this.target, this.subMenuObj);
    }

    @action setSmallMenusShow = ( val ) => {
        this.smallMenuShow = val
    }

}
import React, {  useState , useEffect} from 'react';
import { inject, observer } from 'mobx-react';

import Axios from 'axios';
import makeStyles from '@mui/styles/makeStyles';
import './NoticeDetailmodal.css';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '600px',
        height: '460px',
    },
    body: {
        width: '100%',
        height: '100%',
        justifyContent:'space-between',
        alignItems:'center',
        margin: 'auto',
        bgcolor: 'background.paper',
        backgroundColor : 'white',
        border: '1px solid #ECEEF2',
    },
}));

const NoticeDetail = (props) => {

    const classes = useStyles();
    const [title, setTitle] = useState(null);
    const [title2, setTitle2] = useState(null);

    const [noticeDetailPreOn , setnoticeDetailPreOn] = useState(true);
    const [noticeDetailNextOn , setnoticeDetailNextOn] = useState(true);

    const [ num , setNumId]  = useState(0);
    const [ noticeiId , setNoticeiId]  = useState(0);
    const [ totalRows, setTotalRows] = useState(0);

    const noticeDetailPre = (eventNum,eventnoticeiId) =>{
        setNumId(eventNum);
        setNoticeiId(eventnoticeiId);
        if(eventNum === 1){
            setnoticeDetailPreOn(false);
        }else{
            setnoticeDetailNextOn(true);
            setnoticeDetailPreOn(true);
        }

        const path = 'https://api.rozeus.com/admin/api/v1/cs/notice/detail?notice_id='+eventnoticeiId;
        const config = {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
            },
        }
        Axios.get(path, null, config)
            .then((response) => {
                if(response.data){
                    const targetData = response.data;
                    setTitle(null);
                    setTitle2(null);
                    if(targetData.editor_tp === 2 || targetData.editor_tp === '2'){
                        const text = JSON.parse(response.data.contents).filter.text;
                        response.data.contents = text;
                        setTitle2(targetData);
                    }else if(targetData.editor_tp === 1 || targetData.editor_tp === '1'){
                        setTitle(targetData);
                    }
                    
                }
            }).catch(err => {
                return null;
            });
    }

    const noticeDetailNext = (enentNum,eventnoticeiId) =>{
        setNumId(enentNum);
        setNoticeiId(eventnoticeiId);
        if(totalRows === enentNum){
            setnoticeDetailNextOn(false);
        }else{
            setnoticeDetailNextOn(true);
            setnoticeDetailPreOn(true);
        }

        const path = 'https://api.rozeus.com/admin/api/v1/cs/notice/detail?notice_id='+eventnoticeiId;
        const config = {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
            },
        }
        Axios.get(path, null, config)
            .then((response) => {
                if(response.data){
                    const targetData = response.data;
                    setTitle(null);
                    setTitle2(null);
                    if(targetData.editor_tp === 2 || targetData.editor_tp === '2'){
                        const text = JSON.parse(response.data.contents).filter.text;
                        response.data.contents = text;
                        setTitle2(targetData);
                    }else if(targetData.editor_tp === 1 || targetData.editor_tp === '1'){
                        setTitle(targetData);
                    }
                    
                }
            }).catch(err => {
                return null;
            });
    }
    useEffect(() => {  
        setNumId(props.num);
        setTotalRows(props.row);
        setNoticeiId(props.notice_id);
        if(props.row*1 === props.num*1){
            setnoticeDetailNextOn(false);
        }else{
            setnoticeDetailNextOn(true);
        }

        if(props.num*1 === 1){
            setnoticeDetailPreOn(false);
        }else{
            setnoticeDetailPreOn(true);
        }

        const path = 'https://api.rozeus.com/admin/api/v1/cs/notice/detail?notice_id='+props.notice_id;
        const config = {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
            },
        }
        Axios.get(path, null, config)
            .then((response) => {
                if(response.data){
                    const targetData = response.data;
                    setTitle(null);
                    setTitle2(null);

                    if(targetData.editor_tp === 2 || targetData.editor_tp === '2'){
                        const text = JSON.parse(response.data.contents).filter.text;
                        response.data.contents = text;
                        setTitle2(targetData);
                    }else if(targetData.editor_tp === 1 || targetData.editor_tp === '1'){
                        setTitle(targetData);
                    }
                    
                }
            }).catch(err => {
                return null;
            });
    }, []);
    return (
        
        <div id="NoticeDetail" className={classes.bodyWrap} >
            <div className={classes.body}> 
                <div className='noteiceDetailmodal_text_center noteiceDetailmodal_title'>
                    공지사항&버전정보
                </div>
                <div style={{ height: '20px' }} />
                <div className='noteiceDetailmodal_sub_title' style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
                    {title && (
                            <div key={title.notice_id} className='noticeydetailmodal'>
                                <div className='noticeydetailmodal_black'>
                                    {title.reg_dt}
                                </div>
                                <div className='noticeydetailmodal_ellipsis' dangerouslySetInnerHTML={{__html: title.title}}>
                                </div>
                            </div>
                    )}
                    {title2 && (
                            <div key={title2.notice_id} className='noticeydetailmodal'>
                                <div className='noticeydetailmodal_black'>
                                    {title2.reg_dt}
                                </div>
                                <div className='noticeydetailmodal_ellipsis ' dangerouslySetInnerHTML={{__html: title2.title}}>
                                </div>
                            </div>
                    )}
                </div>
                <div className='noteiceDetailmodal_body_contents' style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
                    {title && (
                        <div key={title.notice_id} className='noticeydetailmodal'>
                            <div className='noticeydetailmodal_contents'>
                                <p dangerouslySetInnerHTML={{__html: title.contents}}></p>
                            </div>
                        </div>
                    )}
                    {title2 && (
                        <div key={title2.notice_id} className='noticeydetailmodal'>
                            <div className='noticeydetailmodal_contents'>
                                <p dangerouslySetInnerHTML={{__html: title2.contents}}></p>
                            </div>
                        </div>
                    )}
                </div>
                <div className='noticeDetailmodal_bottom'>
                    {noticeDetailPreOn &&
                    <div className='noticeDetailmodal_bottom_left' onClick={() => noticeDetailPre(num*1-1 , noticeiId*1+1)}>
                        <div className='noticeDetailmodal_bottom_img'>
                            <img src={'/images/modal/noticedetailmodal/icon_left.png'} alt='noticeymodal_detail'  style={{ width: '16px', height: '24px'}}/>
                        </div>
                        <div className='noticeDetailmodal_bottom_text'>
                            이전글
                        </div>
                    </div>                    
                    }
                    {noticeDetailNextOn &&
                        <div className='noticeDetailmodal_bottom_right' onClick={ () =>  noticeDetailNext(num*1+1 , noticeiId*1-1) }>
                            <div className='noticeDetailmodal_bottom_text'>
                                다음글
                            </div>
                            <div className='noticeDetailmodal_bottom_img'>
                                <img src={'/images/modal/noticedetailmodal/icon_right.png'} alt='noticeymodal_detail'  style={{ width: '16px', height: '24px'}}/>
                            </div>
                        </div>
                    }


                </div>
            </div>
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(NoticeDetail));
import React from 'react';
import { inject, observer } from 'mobx-react';

import makeStyles from '@mui/styles/makeStyles';
import './Servicemodal.css';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '600px',
        height: '446px',
    },
    body: {
        width: '100%',
        height: '100%',
        justifyContent:'space-between',
        alignItems:'center',
        margin: 'auto',
        bgcolor: 'background.paper',
        backgroundColor : 'white',
        border: '1px solid #ECEEF2',
    },
}));

const ServiceModal = ({ layout }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}> 
                <div className='servicemodal_text_center servicemodal_title'>
                    서비스 이용 약관
                </div>
                <div style={{ height: '30px' }} />
                <div className='servicemodal_text_center servicemodal_sub_title'>
                    로제우스는 서비스 제공에 필요한 최소한의 개인정보를 수집·이용합니다.
                </div>
                <div style={{ height: '40px' }} />
                <div className='servicemodal_body_contents '>
                    <div>
                        <div className='servicemodal_title_left_text'>
                            목적
                        </div>
                        <div className='servicemodal_title_left_sub_text'>
                            서비스 기본 기능 제공 (사용자간 소셜 활동 포함)을 위한 이용자 식별 및 회원관리
                        </div>
                        <div className='servicemodal_title_left_sub_text'>
                            이용자간 노출되어 활동 내역 알림 또는 이용자 검색/등록 등의 기능 제공
                        </div>
                        <div className='servicemodal_title_left_sub_text'>
                            이용자 피해 예방을 위한 해외 번호 가입 여부 식별 및 경고 기능 제공
                        </div>
                    </div>
                    <div>
                        <div className='servicemodal_title_right_text'>
                            항목
                        </div>
                        <div className='servicemodal_title_right_sub_text'>
                            휴대폰 번호, 이용자 이름(닉네임), 프로필 사진
                        </div>
                        <div className='servicemodal_title_right_sub_text'>
                            이용자이름(닉네임), 프로필 사진
                        </div>
                        <div className='servicemodal_title_right_sub_text'>
                            휴대폰 번호
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(ServiceModal));
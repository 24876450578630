import React from "react";

import HomeTemplate from "../templates/HomeTemplate";
import Main from "../components/home/Main";
import Chapter1 from "../components/home/Chapter1";
import Chapter2 from "../components/home/Chapter2";
import Chapter3 from "../components/home/Chapter3";
import Chapter4 from "../components/home/Chapter4";
import Chapter5 from "../components/home/Chapter5";
import Chapter6 from "../components/home/Chapter6";
import Chapter7 from "../components/home/Chapter7";

import MMain from "../components/small/home/Main";
import MChapter1 from "../components/small/home/Chapter1";
import MChapter2 from "../components/small/home/Chapter2";
import MChapter3 from "../components/small/home/Chapter3";
import MChapter4 from "../components/small/home/Chapter4";
import MChapter5 from "../components/small/home/Chapter5";
import MChapter6 from "../components/small/home/Chapter6";

import { Hidden } from "@mui/material";

const HomeContainer = () => {
  //   console.log('')

  return (
    <React.Fragment>
      <Hidden smDown>
        <HomeTemplate
          main={<Main />}
          chapter1={<Chapter1 />}
          chapter2={<Chapter2 />}
          chapter3={<Chapter3 />}
          chapter4={<Chapter4 />}
          chapter5={<Chapter5 />}
          chapter6={<Chapter6 />}
          chapter7={<Chapter7 />}
        />
      </Hidden>
      <Hidden smUp>
        <HomeTemplate
          main={<MMain />}
          chapter1={<MChapter1 />}
          chapter2={<MChapter2 />}
          chapter3={<MChapter3 />}
          chapter4={<MChapter4 />}
          chapter5={<MChapter5 />}
          chapter6={<MChapter6 />}
        />
      </Hidden>
    </React.Fragment>
  );
};
export default HomeContainer;

import React from 'react';
import { m_size } from '../../../common/constants'

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
}));

const Main = () => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>

            </div>
        </div>
    );
};

export default Main;
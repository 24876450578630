import React, { useLayoutEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import makeStyles from "@mui/styles/makeStyles";
import ICB from "../../../../components/common/ICB";

import "./SNSIcon.css";

const useStyles = makeStyles((theme) => ({
  bodyWrap: {
    width: "100%",
    margin: "auto",
  },
  body: {
    width: "100%",
  },
  icon: {
    width: "33px",
    height: "33px",
    cursor: "pointer",
  },
}));

const SNSIcon = ({ layout }) => {
  const classes = useStyles();

  const [sizeShow, setSizeShow] = useState(true);

  if (sizeShow) {
  }

  useLayoutEffect(() => {
    function updateSize() {
      window.innerWidth > 400 ? setSizeShow(true) : setSizeShow(false);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className={classes.bodyWrap}>
      <div className={classes.body}>
        <img
          src="/images/small/footer/SNSIcon_background_img.png"
          alt="small_SNSIcon_background_img"
          className="small_SNSIcon_background_img"
        />
        <div style={{ height: "40px" }} />
        <div className="small_SNSIcon_padding">
          <div className="small_SNSIcon_title_top">
            All rights reserved ⓒ Bflysoft Corp 2021
          </div>
          <div style={{ height: "14px" }} />
          <div className="small_SNSIcon_title_center">비플라이소프트㈜</div>
          <div className="small_SNSIcon_title_top">
            서울특별시 중구 퇴계로 385(흥인동 132) 준타워 9층
          </div>
          <div style={{ height: "8px" }} />
          <div className="small_SNSIcon_title_center">팀로제우스</div>
          <div className="small_SNSIcon_title_top">
            서울특별시 중구 퇴계로 385(흥인동 132) 준타워 8층
          </div>
          <div style={{ height: "18px" }} />
          <div className="small_SNSIcon_title_top">
            로제우스는 정확한 뉴스 정보를 수집하기 위해 노력하고 있으며, 정보
            제공에 따른 법적 책임을 지지 않습니다
          </div>
          <div style={{ height: "70px" }} />
          <div style={{ display: "flex", width: "100%", minWidth: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <div
                style={{ display: "flex", position: "absolute", left: "36%" }}
              >
                <ICB
                  imgPath={"/images/small/footer/SNSIcon_ic_blog"}
                  alt="blog"
                  width="30px"
                  height="30px"
                  handleClick={(e) =>
                    window.open("https://blog.naver.com/bflysoft_biz")
                  }
                />
                <div style={{ width: "10px" }} />
                <ICB
                  imgPath={"/images/small/footer/SNSIcon_ic_facebook_normal"}
                  alt="facebook"
                  width="30px"
                  height="30px"
                  handleClick={(e) =>
                    window.open("https://www.facebook.com/rozeuskr/")
                  }
                />
                <div style={{ width: "10px" }} />
                <ICB
                  imgPath={"/images/small/footer/SNSIcon_ic_insta_normal"}
                  alt="insta"
                  width="30px"
                  height="30px"
                  handleClick={(e) =>
                    window.open("https://www.instagram.com/rozeus_official/")
                  }
                />
                <div style={{ width: "10px" }} />
                <ICB
                  imgPath={"/images/small/footer/SNSIcon_ic_twiter_normal"}
                  alt="twiter"
                  width="30px"
                  height="30px"
                  handleClick={(e) =>
                    window.open("https://twitter.com/rozeus2")
                  }
                />
              </div>
            </div>
          </div>
          {!window.ReactNativeWebView && (
            <div>
              <div style={{ height: "100px" }} />
              <ICB
                imgPath={
                  "/images/small/footer/SNSIcon_ic_googleplay_download_normal"
                }
                alt="googleplay"
                width="100%"
                minHeight="50px"
                handleClick={(e) =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.bflysoft.rozeus"
                  )
                }
              />
              <div style={{ height: "10px" }} />
              <ICB
                imgPath={
                  "/images/small/footer/SNSIcon_ic_appstore_download_normal"
                }
                alt="appstore"
                width="100%"
                minHeight="50px"
                handleClick={(e) =>
                  window.open(
                    "https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887"
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject(({ layout }) => ({
  layout: layout,
}))(observer(SNSIcon));

import React, { useEffect } from 'react';
import { size } from '../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import './Chapter5.css';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1027px',
        minWidth:size.imageWidth,
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '1098px',
        margin:'auto',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
    },
}));

const Chapter5 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div>
                <img  srcSet='/images/body/Chapter5_main_bgx1.png 1920w, /images/body/Chapter5_main_bgx2.png 2000w, /images/body/Chapter5_main_bgx3.png 3000w' alt='img_mobile' className='Chapter5_img_main' />
            </div>
            <div className={classes.body}>
                <div className='Chapter5_body_contents'>
                            <React.Fragment>
                                <div className='Chapter5_body_contents_right_cont'>
                                    <div className= 'Chapter5_title_first_head_text'>
                                                        안녕하세요<br />
                                                        TEAM ROZUES입니다.
                                    </div>   
                                    <div style={{ height : "50px"}}></div>
                                    <div className= 'Chapter5_title_first_text'>
                                                        팀로제우스는 "새로운,남들과는 다르게"라는 키워드를 좋아해요<br />
                                                        같은 길을 걸어가기 보다는 새로운 길을 개척하거나 만들어 보자는 생각으로 일하고 있어요.  
                                                        <div style={{ height : "20px"}}></div>
                                                        <div className='Chapter5_title_first_sub_text'>
                                                            미래를 기다려서는 안되며 우리 스스로 만들어야 하는 것이다.  
                                                        </div>
                                    </div>
                                    <div style={{ height : "10px"}}></div>
                                    <div className= 'Chapter5_title_left_second_text'>
                                                        - Simone Weil(시몬 베유) -
                                    </div>
                                    <div className= 'Chapter5_title_left_third_text'>
                                                        <div style={{ height : "20px"}}></div>
                                                        로제우스 사무실 벽면에 있는 글귀인데요,<br />
                                                        이처럼 팀 로제우스는 새로운 길을 남들과 다르게 개척해 나가도록 노력하며<br />
                                                        <div style={{ height : "20px"}}></div>
                                                        믿을수 있고 신뢰할 수 있는 건강한 미디어 생태계를 만들어 가기위해 회사와 함께 성장하고 있어요.                                                        
                                    </div>
                                    <div style={{ height: '60px' }} />
                                    <div className= 'Chapter5_title_left_fifth_text'>
                                                        # 수평적인 소통을 지향해요<br />
                                                        # 엄격한 규칙은 NO,NO<br />
                                                        # 자유로움 속에서 책임감 있게 행동해요<br />
                                                        # 서로 의지하며, 즐겁게 성장하고 있어요
                                    </div>
                                    <div style={{ height: '70px' }} />
                                    <div className= 'Chapter5_title_left_img'>
                                        <img srcSet='/images/body/Chapter5_Framex1.png 1920w, /images/body/Chapter5_Framex2.png 2000w, /images/body/Chapter5_Framex3.png 3000w'  alt='img_mobile' className='Chapter5_img_bottom' />
                                    </div>
                                </div>
                            </React.Fragment>
                    </div>
            </div>
        </div>
    );
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter5));
import React, { useEffect } from 'react';
import { size , aos_option } from '../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import './Chapter7.css';


const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '841px',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '841px',
        minWidth:size.imageWidth,
        maxWidth:size.maxWidth,
        margin:'auto'
    },
}));

const Chapter7 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    return (
        <div className={classes.bodyWrap}>
        <div className={classes.body}>
        <div className='Chapter7_body_contents'>
                        <React.Fragment>
                            <div className='Chapter7_body_contents_right_cont'>
                                <div style={{ height: '80px' }} />
                                <div className= 'Chapter7_title_first_head_text'>
                                                    세상 모든 컨텐츠<br />
                                                    
                                </div>   
                                <div style={{ height: '26px' }} />
                                <div className= 'Chapter7_title_left_top_img'>
                                    <img srcSet='/images/body/Chapter7_titlex1.png 1920w, /images/body/Chapter7_titlex2.png 2000w, /images/body/Chapter7_titlex3.png 3000w' alt='img_mobile' className='Chapter7_img_top' />
                                </div>
                                <div style={{ height: '49.27px' }} />
                                <div className= 'Chapter7_title_left_second_text'>
                                                    지금 바로 로제우스를<br />
                                                    다운로드 하세요!
                                </div>
                                <div style={{ height: '38px' }} />
                                <div className= 'Chapter7_title_left_center_img'>
                                    <img
                                        data-aos='fade-up' 
                                        data-aos-duration={aos_option.duration} 
                                        data-aos-offset={aos_option.offset}                                     
                                        srcSet='/images/body/Chapter7_bottom_bgx1.png 1920w, /images/body/Chapter7_bottom_bgx2.png 2000w, /images/body/Chapter7_bottom_bgx3.png 3000w' 
                                        alt='img_mobile' 
                                        className='Chapter7_img_center' 
                                    />
                                </div> 
                                <div className= 'Chapter7_title_left_bottom_img'>
                                    <img 
                                        data-aos='fade-up' 
                                        data-aos-duration={aos_option.duration} 
                                        data-aos-offset={aos_option.offset}                                    
                                        srcSet='/images/body/Chapter7_bottom_main1.png 1920w, /images/body/Chapter7_bottom_main2.png 2000w, /images/body/Chapter7_bottom_main3.png 3000w' 
                                        alt='img_mobile' 
                                        className='Chapter7_img_bottom' 
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                </div>
        </div>
    </div>
    );
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter7));
import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import makeStyles from '@mui/styles/makeStyles';

import { inject, observer } from 'mobx-react';
import './Chapter3.css'

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 419px',
        backgroundSize: '65% auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
    },
}));

const Chapter3 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className='small_Chapter3_title_body_background_img'>
                <img 
                    data-aos='fade-up' 
                    data-aos-duration={aos_option.duration} 
                    data-aos-offset={aos_option.offset}     
                    srcSet='/images/small/body/small_Chapter3_bgx1.png 214w, /images/small/body/small_Chapter3_bgx2.png 414w, /images/small/body/small_Chapter3_bgx3.png 614w' 
                    alt='Chapter3_background_first_img' 
                    className='small_Chapter3_title_body_background_img'
                />
            </div>
            <div className={classes.body}>
                <div className='Chapter3_first_img'>
                    <img 
                        data-aos='fade-up' 
                        data-aos-duration={aos_option.duration} 
                        data-aos-offset={aos_option.offset} 
                        srcSet='/images/small/body/small_Chapter3_firstx1.png 214w, /images/small/body/small_Chapter3_firstx2.png 414w, /images/small/body/small_Chapter3_firstx3.png 614w' 
                        alt='Chapter3_first_img' 
                        className='Chapter3_first_img'
                    />
                </div> 
                <div style={{height:'25px'}}></div>
                <div className='small_Chapter3_title_body_position'>
                    <div
                        data-aos='fade-up' 
                        data-aos-duration={aos_option.duration} 
                        data-aos-offset={aos_option.offset}    
                    >
                        <div className='small_Chapter3_title_body_first_text'>
                                                        실시간 검색어 랭킹                 
                        </div>
                        <div style={{height:'20px'}}></div>
                        <div className='small_Chapter3_title_body_second_text'>
                                                    더욱 강력해진<br />
                                                    실시간 검색어 랭킹<br />
                                                    연관 키워드 분석으로<br />
                                                    폭 넓은 이슈탐색까지!              
                        </div>
                        <div style={{height:'16px'}}></div>
                        <div className='small_Chapter3_title_body_third_text'>
                                                    이제는 로제우스에서<br />
                                                    실시간 검색어 랭킹을 만나보세요<br />
                                                    궁금하지만 사라져버린 실시간 검색어 서비스,<br />
                                                    로제우스에 있습니다!<br />
                                                    실시간 검색어 랭킹의 키워드를<br />
                                                    AI빅데이터 분석을 통해<br />
                                                    이슈 키워드, 긍정 키워드, 부정 키워드 관련 뉴스<br />
                                                    까지 확인할 수 있어요            
                        </div>
                    </div>
                    <div style={{height:'74px'}}></div>
                    <div className='small_Chapter3_second_img'>
                        <img 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset}     
                            srcSet='/images/small/body/small_Chapter3_secondx1.png 214w, /images/small/body/small_Chapter3_secondx2.png 414w, /images/small/body/small_Chapter3_secondx3.png 614w' 
                            alt='small_Chapter3_second_img' 
                            className='small_Chapter3_second_img'
                        />
                    </div> 
                    <div style={{height:'25px'}}></div>       
                    <div
                        data-aos='fade-up' 
                        data-aos-duration={aos_option.duration} 
                        data-aos-offset={aos_option.offset}   
                    >
                        <div className='small_Chapter3_title_body_first_text'>
                                                        다른 의견 브리핑                 
                        </div>
                        <div style={{height:'20px'}}></div>
                        <div className='small_Chapter3_title_body_second_text'>
                                                    하나의 이슈를<br />
                                                    다양한 시선으로<br />
                                                    볼 수 있도록 도와드려요            
                        </div>
                        <div style={{height:'16px'}}></div>
                        <div className='small_Chapter3_title_body_third_text'>
                                                    로제우스에서 준비했습니다.<br />
                                                    왜곡된 시선으로 바라보는 뉴스가 아닌<br />
                                                    사용자에게 올바른 인사이트를 제공하기 위한         
                        </div>
                        <div className='small_Chapter3_title_body_third_sub_text'>
                                                    "다른의견 뉴스보기"         
                        </div>
                        <div className='small_Chapter3_title_body_third_text'>
                                                    내가 선택한 기사를<br />
                                                    다양한 관점에서 생각할 수 있도록      
                        </div>    
                        <div className='small_Chapter3_title_body_third_sub_text'>
                                                    비슷한 의견 기사 vs 다른 의견 기사를      
                        </div>    
                        <div className='small_Chapter3_title_body_third_text'>
                                                    볼 수 있어요   
                        </div>      
                    </div>           

                    <div style={{height:'74px'}}></div>
                    <div className='small_Chapter3_third_img'>
                        <img 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset} 
                            srcSet='/images/small/body/small_Chapter3_thirdx1.png 214w, /images/small/body/small_Chapter3_thirdx2.png 414w, /images/small/body/small_Chapter3_thirdx3.png 614w' 
                            alt='small_Chapter3_third_img' 
                            className='small_Chapter3_third_img'
                        />
                    </div> 
                    <div style={{height:'25px'}}></div>
                    <div
                        data-aos='fade-up' 
                        data-aos-duration={aos_option.duration} 
                        data-aos-offset={aos_option.offset}   
                    >
                        <div className='small_Chapter3_title_body_fourth_text'>
                                                        뉴스룸                 
                        </div>
                        <div style={{height:'20px'}}></div>
                        <div className='small_Chapter3_title_body_Fifth_text'>
                                                    작은 소식을<br />
                                                    큰 이야기로 만들어보세요!         
                        </div>
                        <div style={{height:'16px'}}></div>
                        <div className='small_Chapter3_title_body_sixth_text'>
                                                    뉴스뿐만 아니라 세상의 모든 컨텐츠를<br />
                                                    모아서 이야기 할 수 있어요<br />
                                                    전문적인 소통과 깊이 있는 정보를<br />
                                                    <div style={{ display:'flex' , justifyContent :'center'}}>
                                                        <div className='small_Chapter3_title_body_sixth_text'>
                                                            찾는다면 ,
                                                        </div>
                                                        <div className='small_Chapter3_title_body_sixth_text_blon'>
                                                            공식 뉴스룸 [팔로우] 클릭!<br />
                                                        </div>
                                                    </div>
                                                    취향과 관심사가 같은 사람들과 소통을<br />
                                                    <div style={{ display:'flex' , justifyContent :'center'}}>
                                                        <div className='small_Chapter3_title_body_sixth_text'>
                                                        원한다면 ,
                                                        </div>
                                                        <div className='small_Chapter3_title_body_sixth_text_blon'>
                                                        관심사 뉴스룸 [팔로우] 클릭!<br />
                                                        </div>
                                                    </div>
                                                    내가 직접 포스트를 작성하고,<br />
                                                    다른사람들의 포스트를 리포스트하며<br />
                                                    다양한 생각을 공유하세요.<br />
                                                    <div style={{height:'10px'}}></div>
                                                    나만의 개인공간 "개인 뉴스룸"<br />
                                                    함께하면 더 즐거운 공간 "커뮤니티 뉴스룸"
                        </div>
                    </div>

                    <div style={{height:'74px'}}></div>
                    <div className='small_Chapter3_fourth_img'>
                        <img 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset}   
                            srcSet='/images/small/body/small_Chapter3_fourthx1.png 214w, /images/small/body/small_Chapter3_fourthx2.png 414w, /images/small/body/small_Chapter3_fourthx3.png 614w' 
                            alt='small_Chapter3_fourth_img' 
                            className='small_Chapter3_fourth_img'
                        />
                    </div> 
                    <div style={{height:'25px'}}></div>
                    <div 
                        data-aos='fade-up' 
                        data-aos-duration={aos_option.duration} 
                        data-aos-offset={aos_option.offset}   
                    >
                        <div className='small_Chapter3_title_body_fourth_text'>
                                                        생활정보                 
                        </div>
                        <div style={{height:'20px'}}></div>
                        <div className='small_Chapter3_title_body_Fifth_text'>
                                                    내 생활 속에 쏙!<br />
                                                    생활 밀착형 뉴스를 한번에!    
                        </div>
                        <div style={{height:'16px'}}></div>
                        <div className='small_Chapter3_title_body_sixth_text'>
                                                    로제우스에선 코로나현황, 날씨, 금융 뉴스를<br />
                                                    한눈에 확인 가능<br />
                                                    아직도 코로나와 금융 뉴스는 네이버,<br />
                                                    날씨는 다음에서 찾으시나요?<br />
                                                    로제우스에서는 한번에 쉽게 확인 가능해요!<br />
                                                    뿐만 아니라,다른 곳에서 제공하지 않는<br />
                                                    실시간 거리두기 현황과<br />
                                                    내 주식 및 가상화폐의 가격변동까지<br />
                                                    확인할 수 있어요.
                        </div>
                        <div style={{height:'80px'}}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3));
import React, { useCallback, useEffect, useState } from 'react';
import { size } from '../../common/constants'

import { AppBar, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from 'mobx-react';
import { throttle } from 'lodash';
import { height } from '@mui/system';
import { isAndroid, isIOS } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    banner : {
        minWidth: size.toolbarWidth,
    },
    toolbar : {
        width:'100%',
        maxWidth: size.fullmaxWidth,
        minWidth: size.fullminWidth,
        margin:'auto',
        height:'60px',
        display:'flex',
        alignItems:'center'
    },
    toolbar2 : {
        width:'100%',
        margin:'auto',
        maxWidth: size.fullmaxWidth,
        minWidth: size.fullminWidth,
        height:'60px',
        display:'flex',
        alignItems:'center'
    },
    toolbarContent : {
        display: 'flex',
        justifyContent:'space-between',
        width: '100%',
        minWidth: size.fullminWidth,
        maxWidth: size.fullminWidth,
    },
    menuArea:{
        marginLeft:'60px',
        height:'60px',
        display:'flex',
    },
    iconArea: {
        height: '60px',
        display: 'flex',
    },
    toolbarWidthColor: {
        minWidth: size.toolbarWidth,
    },
    Header_banner:{
        height: '120px'
    },
    Header_banner_exit:{
        width: '42px',
        height: '42px',
        position: 'relative',
        marginTop: '13px',
        left: '1122px'
    }
}));

const Header = ({layout }) => {
    const classes = useStyles();
    const [eleVal, setEleVal] = useState(false);
    const [eleVal2, setEleVal2] = useState(false);

    if(eleVal){}

    const handleBannerClick = event => {
        if(isAndroid===true){
            window.location.href = 'https://play.google.com/store/apps/details?id=com.bflysoft.rozeus'
        }
        if(isIOS===true){
            window.location.href = 'https://apps.apple.com/kr/app/로제우스-rozeus-꼭-필요한-뉴스/id1570566887'
        }
        if(isAndroid ===false || isIOS ===false){
            window.location.href = "https://www.rozeus.com/home"
        }
    };
    
    const handleBannerExitClick = event => {
        layout.setBanner(false)
    }

    const listener = useCallback(() => {
        if (window.scrollY || window.pageYOffset > 0) {
            setEleVal(true);
        } else {
            setEleVal(false);
        };
        if (window.scrollY && window.pageYOffset > 1) {
            setEleVal2(true);
        } else {
            setEleVal2(false);
        };

        
    }, []);

    useEffect(() => {
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener);
        return () => {
            window.removeEventListener("scroll", throttleListener);
        };
    }, [listener]);

    return (
        <React.Fragment>
            <div id='back-to-top-anchor' />
            <Collapse in={layout.banner}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#F8EFE4'
                    }}
                    className={classes.banner}
                >
                    <img src='/images/common/ic_close_btn_normal.svg' alt='Floating' className={classes.Header_banner_exit} style={{zIndex: 5}} onClick={(e) => handleBannerExitClick()}/>
                    <img src='/images/common/top_banner.png' alt='Floating' className={classes.Header_banner} style={{zIndex: 1}} onClick={(e) => handleBannerClick(false)}/>
                </div>
            </Collapse>
            { !eleVal2 &&
                <div className={classes.toolbar}>
                    <div className={classes.toolbarContent}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{display:'flex',alignItems:'center',cursor:'pointer' ,  paddingLeft: '40px'}}>
                            <img src={'/images/common/logo.png'} alt='logo_rozeus' style={{ width: '172px', height: '30px'}} onClick={(e) => window.location.href = '/'}/>
                        </div>
                        </div>
                    </div>
                </div>
            }
            { eleVal2 &&< AppBar position="sticky" elevation={0} className={classes.toolbarWidthColor}>
                <div className={classes.toolbar2}>
                    <div className={classes.toolbarContent}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{display:'flex',alignItems:'center',cursor:'pointer' , paddingLeft: '40px'}}>
                            <img src={'/images/common/logo.png'} alt='logo_rozeus' style={{ width: '172px', height: '30px'}} onClick={(e) => window.location.href = '/'}/>
                        </div>
                        </div>
                    </div>
                </div>
            </AppBar> }
        </React.Fragment>
    );
}
export default inject(({ layout, menu }) => ({
    layout: layout,
    menu: menu
}))(observer(Header));
